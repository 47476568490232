.knowledge-button-container {
  position: relative;

  .breadcrumb {
    width: calc(100% - 200px);
  }

  .product-knowledge-button {
    position: absolute;
    right: 55px;
    top: -1px;
    background: #0288d1;
    color: white;
    font-size: 14px;
    padding: 9px 17px;
    border-radius: 28px;
  }
}
