.breadcrumb {
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 65px;
  }

  &-item {
    &.active {
      font-weight: 700;
    }

    &.home {
      padding-right: 4px;

      svg {
        vertical-align: baseline;
      }
    }

    a {
      color: $black;
    }
  }
}
