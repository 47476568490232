.introSection {
  background-color: #5c83b5;
  background-image: url(../images/intro2.jpg);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #0D1837;
  margin-top: -175px;
  padding-top: 230px;
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-top: 260px;
    padding-bottom: 110px;
  }

  @include media-breakpoint-up(xl) {
    background-position: 50% 100%;
  }

  @media (min-width: 1440px) {
    // background-size: cover;
  }

  &__content {
    @include media-breakpoint-up(lg) {
      max-width: 700px;
      margin-left: auto;
    }
  }

  &__title {
    color: inherit;
    font-weight: 400;

    @include media-breakpoint-up(lg) {
      font-size: 60px;
    }
  }

  &__description {
    font-size: 27px;
  }

  &__features {
    margin-top: 70px;
  }

  &__footer {
    margin-top: 20px;
  }

  &__btn {
    background-color: #ffc009;
    color: #0d1837;
    border-color: #ffc009;
  }
}

.introSectionFeatures {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }

  &__item {
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
      width: 50%;
    }

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    height: 94px;
    margin-bottom: 25px;

    @include media-breakpoint-up(sm) {
      border-right: 2px solid #0D1837;
      padding: 0 40px;
    }

    img {
      margin: auto;
    }
  }

  &__text {
    font-size: 16px;
    text-align: center;
    line-height: 1.3;

    @include media-breakpoint-up(sm) {
      padding: 0 35px;
    }
  }

  @include media-breakpoint-only(sm) {
    &__item:nth-child(odd) &__icon,
    &__item:nth-child(odd) &__text {
      padding-left: 0;
    }

    &__item:nth-child(even) &__icon,
    &__item:nth-child(even) &__text {
      border-right: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-up(md) {
    &__item:first-child &__icon,
    &__item:first-child &__text {
      padding-left: 0;
    }

    &__item:last-child &__icon,
    &__item:last-child &__text {
      border-right: 0;
      padding-right: 0;
    }
  }
}
