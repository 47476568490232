.appSearch {
  display: flex;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(lg) {
    margin-left: 15px;
    width: 190px;
  }

  &__control {
    background-color: transparent;
    height: 28px;
    line-height: 18px;
    padding-right: 42px;
    padding-left: 12px;
    color: white;
  }

  &__btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 40px;
    height: 28px;

    &:hover,
    &:focus {
      background-color: #fff;
      color: $primary;
    }

    svg {
      vertical-align: -2px;
    }
  }

  &__control:focus + &__btn {
    color: $primary;
  }
}

.searchForm {
  margin-bottom: 20px;
  position: relative;
  width: 50%;

  &__btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    top: 1px;
    right: 0;
    padding: 0;
    width: 50px;
    height: 48px;
    color: $primary;

    &:focus {
      box-shadow: none;
      background-color: $primary;
      color: #ffffff;
    }
  }
}

.searchResults {

  padding-bottom: 2em;

  &__list {
    padding-top: 1em;
  }

  &__item {
    margin-bottom: 2em;

    &__description {
      margin-bottom: 10px;

      em {
        background-color: yellow;
        font-style: normal;
      }
    }
  }

  &__pagination {
    a {
      padding-right: 1em;
    }
  }
}
