/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #162C69;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #eff3f8;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ae1a22;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #6b1015;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.8125rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 25px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 25px; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bec4d5; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8691b1; }

.table-hover .table-primary:hover {
  background-color: #afb6cb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #afb6cb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfcfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f7f9fb; }

.table-hover .table-light:hover {
  background-color: #eaeff5; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaeff5; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 25px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  .form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: #2c58d2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.29; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.29em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.29;
  border-radius: 25px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 36px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 25px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 25px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 7px 31px;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 25px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #162C69;
  border-color: #162C69; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0f1f49;
    border-color: #0d1a3f; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0f1f49;
    border-color: #0d1a3f;
    box-shadow: 0 0 0 0.2rem rgba(57, 76, 128, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #162C69;
    border-color: #162C69; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0d1a3f;
    border-color: #0b1634; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 76, 128, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #353535;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #353535;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #353535;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(225, 172, 14, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #353535;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #353535;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 172, 14, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #353535;
  background-color: #eff3f8;
  border-color: #eff3f8; }
  .btn-light:hover {
    color: #353535;
    background-color: #d4dfec;
    border-color: #ccd8e8; }
  .btn-light:focus, .btn-light.focus {
    color: #353535;
    background-color: #d4dfec;
    border-color: #ccd8e8;
    box-shadow: 0 0 0 0.2rem rgba(211, 215, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #353535;
    background-color: #eff3f8;
    border-color: #eff3f8; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #353535;
    background-color: #ccd8e8;
    border-color: #c3d2e5; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 215, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #162C69;
  border-color: #162C69; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #162C69;
    border-color: #162C69; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #162C69;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #162C69;
    border-color: #162C69; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #353535;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #353535;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #eff3f8;
  border-color: #eff3f8; }
  .btn-outline-light:hover {
    color: #353535;
    background-color: #eff3f8;
    border-color: #eff3f8; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 248, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #eff3f8;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #353535;
    background-color: #eff3f8;
    border-color: #eff3f8; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 243, 248, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #ae1a22;
  text-decoration: none; }
  .btn-link:hover {
    color: #6b1015;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 12px 50px;
  font-size: 1.375rem;
  line-height: 1.5;
  border-radius: 36px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 23px;
  font-size: 0.8125rem;
  line-height: 1.39;
  border-radius: 25px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 25px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #162C69; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 23.25px;
  padding-left: 23.25px; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 17.25px;
  padding-left: 17.25px; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 37.5px;
  padding-left: 37.5px; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 25px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 36px; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.29em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.29;
  border-radius: 25px; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #162C69;
    background-color: #162C69; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #2c58d2; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #5679dc;
    border-color: #5679dc; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 25px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #162C69;
  background-color: #162C69; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 44, 105, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(22, 44, 105, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 44, 105, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(22, 44, 105, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 25px;
  appearance: none; }
  .custom-select:focus {
    border-color: #2c58d2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
    .custom-select:focus::-ms-value {
      color: #000;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }

.custom-select-sm {
  height: calc(1.29em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #2c58d2;
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 25px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 25px 25px 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #162C69;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #5679dc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #162C69;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #5679dc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #162C69;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #5679dc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 25px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #162C69; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 25px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 25px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 24px 24px 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 24px 24px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.625rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.625rem;
      color: #000;
      content: ">"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #000; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 25px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ae1a22;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #6b1015;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px; }

.page-item:last-child .page-link {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #162C69;
  border-color: #162C69; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 25px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #162C69; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0d1a3f; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 44, 105, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #353535;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #353535;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #353535;
  background-color: #eff3f8; }
  a.badge-light:hover, a.badge-light:focus {
    color: #353535;
    background-color: #ccd8e8; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 248, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 36px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 25px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #0b1737;
  background-color: #d0d5e1;
  border-color: #bec4d5; }
  .alert-primary hr {
    border-top-color: #afb6cb; }
  .alert-primary .alert-link {
    color: #03050d; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #7c7e81;
  background-color: #fcfdfe;
  border-color: #fbfcfd; }
  .alert-light hr {
    border-top-color: #eaeff5; }
  .alert-light .alert-link {
    color: #636567; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 25px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #162C69;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 25px; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #000;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #162C69;
    border-color: #162C69; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 25px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 25px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 25px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 25px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #0b1737;
  background-color: #bec4d5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0b1737;
    background-color: #afb6cb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0b1737;
    border-color: #0b1737; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #7c7e81;
  background-color: #fbfcfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7c7e81;
    background-color: #eaeff5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7e81;
    border-color: #7c7e81; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 36px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 25px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 36px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 36px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 36px 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 36px 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #162C69 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0d1a3f !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #eff3f8 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ccd8e8 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #162C69 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #eff3f8 !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 25px !important; }

.rounded {
  border-radius: 25px !important; }

.rounded-top {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important; }

.rounded-right {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important; }

.rounded-bottom {
  border-bottom-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important; }

.rounded-left {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important; }

.rounded-lg {
  border-radius: 36px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #162C69 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #09112a !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #eff3f8 !important; }

a.text-light:hover, a.text-light:focus {
  color: #bacbe1 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?856008caa5eb66df68595e734e59580d") format("woff2"), url("./fonts/bootstrap-icons.woff?856008caa5eb66df68595e734e59580d") format("woff"); }

[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bi-alarm-fill::before {
  content: "\f101"; }

.bi-alarm::before {
  content: "\f102"; }

.bi-align-bottom::before {
  content: "\f103"; }

.bi-align-center::before {
  content: "\f104"; }

.bi-align-end::before {
  content: "\f105"; }

.bi-align-middle::before {
  content: "\f106"; }

.bi-align-start::before {
  content: "\f107"; }

.bi-align-top::before {
  content: "\f108"; }

.bi-alt::before {
  content: "\f109"; }

.bi-app-indicator::before {
  content: "\f10a"; }

.bi-app::before {
  content: "\f10b"; }

.bi-archive-fill::before {
  content: "\f10c"; }

.bi-archive::before {
  content: "\f10d"; }

.bi-arrow-90deg-down::before {
  content: "\f10e"; }

.bi-arrow-90deg-left::before {
  content: "\f10f"; }

.bi-arrow-90deg-right::before {
  content: "\f110"; }

.bi-arrow-90deg-up::before {
  content: "\f111"; }

.bi-arrow-bar-down::before {
  content: "\f112"; }

.bi-arrow-bar-left::before {
  content: "\f113"; }

.bi-arrow-bar-right::before {
  content: "\f114"; }

.bi-arrow-bar-up::before {
  content: "\f115"; }

.bi-arrow-clockwise::before {
  content: "\f116"; }

.bi-arrow-counterclockwise::before {
  content: "\f117"; }

.bi-arrow-down-circle-fill::before {
  content: "\f118"; }

.bi-arrow-down-circle::before {
  content: "\f119"; }

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a"; }

.bi-arrow-down-left-circle::before {
  content: "\f11b"; }

.bi-arrow-down-left-square-fill::before {
  content: "\f11c"; }

.bi-arrow-down-left-square::before {
  content: "\f11d"; }

.bi-arrow-down-left::before {
  content: "\f11e"; }

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f"; }

.bi-arrow-down-right-circle::before {
  content: "\f120"; }

.bi-arrow-down-right-square-fill::before {
  content: "\f121"; }

.bi-arrow-down-right-square::before {
  content: "\f122"; }

.bi-arrow-down-right::before {
  content: "\f123"; }

.bi-arrow-down-short::before {
  content: "\f124"; }

.bi-arrow-down-square-fill::before {
  content: "\f125"; }

.bi-arrow-down-square::before {
  content: "\f126"; }

.bi-arrow-down-up::before {
  content: "\f127"; }

.bi-arrow-down::before {
  content: "\f128"; }

.bi-arrow-left-circle-fill::before {
  content: "\f129"; }

.bi-arrow-left-circle::before {
  content: "\f12a"; }

.bi-arrow-left-right::before {
  content: "\f12b"; }

.bi-arrow-left-short::before {
  content: "\f12c"; }

.bi-arrow-left-square-fill::before {
  content: "\f12d"; }

.bi-arrow-left-square::before {
  content: "\f12e"; }

.bi-arrow-left::before {
  content: "\f12f"; }

.bi-arrow-repeat::before {
  content: "\f130"; }

.bi-arrow-return-left::before {
  content: "\f131"; }

.bi-arrow-return-right::before {
  content: "\f132"; }

.bi-arrow-right-circle-fill::before {
  content: "\f133"; }

.bi-arrow-right-circle::before {
  content: "\f134"; }

.bi-arrow-right-short::before {
  content: "\f135"; }

.bi-arrow-right-square-fill::before {
  content: "\f136"; }

.bi-arrow-right-square::before {
  content: "\f137"; }

.bi-arrow-right::before {
  content: "\f138"; }

.bi-arrow-up-circle-fill::before {
  content: "\f139"; }

.bi-arrow-up-circle::before {
  content: "\f13a"; }

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b"; }

.bi-arrow-up-left-circle::before {
  content: "\f13c"; }

.bi-arrow-up-left-square-fill::before {
  content: "\f13d"; }

.bi-arrow-up-left-square::before {
  content: "\f13e"; }

.bi-arrow-up-left::before {
  content: "\f13f"; }

.bi-arrow-up-right-circle-fill::before {
  content: "\f140"; }

.bi-arrow-up-right-circle::before {
  content: "\f141"; }

.bi-arrow-up-right-square-fill::before {
  content: "\f142"; }

.bi-arrow-up-right-square::before {
  content: "\f143"; }

.bi-arrow-up-right::before {
  content: "\f144"; }

.bi-arrow-up-short::before {
  content: "\f145"; }

.bi-arrow-up-square-fill::before {
  content: "\f146"; }

.bi-arrow-up-square::before {
  content: "\f147"; }

.bi-arrow-up::before {
  content: "\f148"; }

.bi-arrows-angle-contract::before {
  content: "\f149"; }

.bi-arrows-angle-expand::before {
  content: "\f14a"; }

.bi-arrows-collapse::before {
  content: "\f14b"; }

.bi-arrows-expand::before {
  content: "\f14c"; }

.bi-arrows-fullscreen::before {
  content: "\f14d"; }

.bi-arrows-move::before {
  content: "\f14e"; }

.bi-aspect-ratio-fill::before {
  content: "\f14f"; }

.bi-aspect-ratio::before {
  content: "\f150"; }

.bi-asterisk::before {
  content: "\f151"; }

.bi-at::before {
  content: "\f152"; }

.bi-award-fill::before {
  content: "\f153"; }

.bi-award::before {
  content: "\f154"; }

.bi-back::before {
  content: "\f155"; }

.bi-backspace-fill::before {
  content: "\f156"; }

.bi-backspace-reverse-fill::before {
  content: "\f157"; }

.bi-backspace-reverse::before {
  content: "\f158"; }

.bi-backspace::before {
  content: "\f159"; }

.bi-badge-3d-fill::before {
  content: "\f15a"; }

.bi-badge-3d::before {
  content: "\f15b"; }

.bi-badge-4k-fill::before {
  content: "\f15c"; }

.bi-badge-4k::before {
  content: "\f15d"; }

.bi-badge-8k-fill::before {
  content: "\f15e"; }

.bi-badge-8k::before {
  content: "\f15f"; }

.bi-badge-ad-fill::before {
  content: "\f160"; }

.bi-badge-ad::before {
  content: "\f161"; }

.bi-badge-ar-fill::before {
  content: "\f162"; }

.bi-badge-ar::before {
  content: "\f163"; }

.bi-badge-cc-fill::before {
  content: "\f164"; }

.bi-badge-cc::before {
  content: "\f165"; }

.bi-badge-hd-fill::before {
  content: "\f166"; }

.bi-badge-hd::before {
  content: "\f167"; }

.bi-badge-tm-fill::before {
  content: "\f168"; }

.bi-badge-tm::before {
  content: "\f169"; }

.bi-badge-vo-fill::before {
  content: "\f16a"; }

.bi-badge-vo::before {
  content: "\f16b"; }

.bi-badge-vr-fill::before {
  content: "\f16c"; }

.bi-badge-vr::before {
  content: "\f16d"; }

.bi-badge-wc-fill::before {
  content: "\f16e"; }

.bi-badge-wc::before {
  content: "\f16f"; }

.bi-bag-check-fill::before {
  content: "\f170"; }

.bi-bag-check::before {
  content: "\f171"; }

.bi-bag-dash-fill::before {
  content: "\f172"; }

.bi-bag-dash::before {
  content: "\f173"; }

.bi-bag-fill::before {
  content: "\f174"; }

.bi-bag-plus-fill::before {
  content: "\f175"; }

.bi-bag-plus::before {
  content: "\f176"; }

.bi-bag-x-fill::before {
  content: "\f177"; }

.bi-bag-x::before {
  content: "\f178"; }

.bi-bag::before {
  content: "\f179"; }

.bi-bar-chart-fill::before {
  content: "\f17a"; }

.bi-bar-chart-line-fill::before {
  content: "\f17b"; }

.bi-bar-chart-line::before {
  content: "\f17c"; }

.bi-bar-chart-steps::before {
  content: "\f17d"; }

.bi-bar-chart::before {
  content: "\f17e"; }

.bi-basket-fill::before {
  content: "\f17f"; }

.bi-basket::before {
  content: "\f180"; }

.bi-basket2-fill::before {
  content: "\f181"; }

.bi-basket2::before {
  content: "\f182"; }

.bi-basket3-fill::before {
  content: "\f183"; }

.bi-basket3::before {
  content: "\f184"; }

.bi-battery-charging::before {
  content: "\f185"; }

.bi-battery-full::before {
  content: "\f186"; }

.bi-battery-half::before {
  content: "\f187"; }

.bi-battery::before {
  content: "\f188"; }

.bi-bell-fill::before {
  content: "\f189"; }

.bi-bell::before {
  content: "\f18a"; }

.bi-bezier::before {
  content: "\f18b"; }

.bi-bezier2::before {
  content: "\f18c"; }

.bi-bicycle::before {
  content: "\f18d"; }

.bi-binoculars-fill::before {
  content: "\f18e"; }

.bi-binoculars::before {
  content: "\f18f"; }

.bi-blockquote-left::before {
  content: "\f190"; }

.bi-blockquote-right::before {
  content: "\f191"; }

.bi-book-fill::before {
  content: "\f192"; }

.bi-book-half::before {
  content: "\f193"; }

.bi-book::before {
  content: "\f194"; }

.bi-bookmark-check-fill::before {
  content: "\f195"; }

.bi-bookmark-check::before {
  content: "\f196"; }

.bi-bookmark-dash-fill::before {
  content: "\f197"; }

.bi-bookmark-dash::before {
  content: "\f198"; }

.bi-bookmark-fill::before {
  content: "\f199"; }

.bi-bookmark-heart-fill::before {
  content: "\f19a"; }

.bi-bookmark-heart::before {
  content: "\f19b"; }

.bi-bookmark-plus-fill::before {
  content: "\f19c"; }

.bi-bookmark-plus::before {
  content: "\f19d"; }

.bi-bookmark-star-fill::before {
  content: "\f19e"; }

.bi-bookmark-star::before {
  content: "\f19f"; }

.bi-bookmark-x-fill::before {
  content: "\f1a0"; }

.bi-bookmark-x::before {
  content: "\f1a1"; }

.bi-bookmark::before {
  content: "\f1a2"; }

.bi-bookmarks-fill::before {
  content: "\f1a3"; }

.bi-bookmarks::before {
  content: "\f1a4"; }

.bi-bookshelf::before {
  content: "\f1a5"; }

.bi-bootstrap-fill::before {
  content: "\f1a6"; }

.bi-bootstrap-reboot::before {
  content: "\f1a7"; }

.bi-bootstrap::before {
  content: "\f1a8"; }

.bi-border-all::before {
  content: "\f1a9"; }

.bi-border-bottom::before {
  content: "\f1aa"; }

.bi-border-center::before {
  content: "\f1ab"; }

.bi-border-inner::before {
  content: "\f1ac"; }

.bi-border-left::before {
  content: "\f1ad"; }

.bi-border-middle::before {
  content: "\f1ae"; }

.bi-border-outer::before {
  content: "\f1af"; }

.bi-border-right::before {
  content: "\f1b0"; }

.bi-border-style::before {
  content: "\f1b1"; }

.bi-border-top::before {
  content: "\f1b2"; }

.bi-border-width::before {
  content: "\f1b3"; }

.bi-border::before {
  content: "\f1b4"; }

.bi-bounding-box-circles::before {
  content: "\f1b5"; }

.bi-bounding-box::before {
  content: "\f1b6"; }

.bi-box-arrow-down-left::before {
  content: "\f1b7"; }

.bi-box-arrow-down-right::before {
  content: "\f1b8"; }

.bi-box-arrow-down::before {
  content: "\f1b9"; }

.bi-box-arrow-in-down-left::before {
  content: "\f1ba"; }

.bi-box-arrow-in-down-right::before {
  content: "\f1bb"; }

.bi-box-arrow-in-down::before {
  content: "\f1bc"; }

.bi-box-arrow-in-left::before {
  content: "\f1bd"; }

.bi-box-arrow-in-right::before {
  content: "\f1be"; }

.bi-box-arrow-in-up-left::before {
  content: "\f1bf"; }

.bi-box-arrow-in-up-right::before {
  content: "\f1c0"; }

.bi-box-arrow-in-up::before {
  content: "\f1c1"; }

.bi-box-arrow-left::before {
  content: "\f1c2"; }

.bi-box-arrow-right::before {
  content: "\f1c3"; }

.bi-box-arrow-up-left::before {
  content: "\f1c4"; }

.bi-box-arrow-up-right::before {
  content: "\f1c5"; }

.bi-box-arrow-up::before {
  content: "\f1c6"; }

.bi-box-seam::before {
  content: "\f1c7"; }

.bi-box::before {
  content: "\f1c8"; }

.bi-braces::before {
  content: "\f1c9"; }

.bi-bricks::before {
  content: "\f1ca"; }

.bi-briefcase-fill::before {
  content: "\f1cb"; }

.bi-briefcase::before {
  content: "\f1cc"; }

.bi-brightness-alt-high-fill::before {
  content: "\f1cd"; }

.bi-brightness-alt-high::before {
  content: "\f1ce"; }

.bi-brightness-alt-low-fill::before {
  content: "\f1cf"; }

.bi-brightness-alt-low::before {
  content: "\f1d0"; }

.bi-brightness-high-fill::before {
  content: "\f1d1"; }

.bi-brightness-high::before {
  content: "\f1d2"; }

.bi-brightness-low-fill::before {
  content: "\f1d3"; }

.bi-brightness-low::before {
  content: "\f1d4"; }

.bi-broadcast-pin::before {
  content: "\f1d5"; }

.bi-broadcast::before {
  content: "\f1d6"; }

.bi-brush-fill::before {
  content: "\f1d7"; }

.bi-brush::before {
  content: "\f1d8"; }

.bi-bucket-fill::before {
  content: "\f1d9"; }

.bi-bucket::before {
  content: "\f1da"; }

.bi-bug-fill::before {
  content: "\f1db"; }

.bi-bug::before {
  content: "\f1dc"; }

.bi-building::before {
  content: "\f1dd"; }

.bi-bullseye::before {
  content: "\f1de"; }

.bi-calculator-fill::before {
  content: "\f1df"; }

.bi-calculator::before {
  content: "\f1e0"; }

.bi-calendar-check-fill::before {
  content: "\f1e1"; }

.bi-calendar-check::before {
  content: "\f1e2"; }

.bi-calendar-date-fill::before {
  content: "\f1e3"; }

.bi-calendar-date::before {
  content: "\f1e4"; }

.bi-calendar-day-fill::before {
  content: "\f1e5"; }

.bi-calendar-day::before {
  content: "\f1e6"; }

.bi-calendar-event-fill::before {
  content: "\f1e7"; }

.bi-calendar-event::before {
  content: "\f1e8"; }

.bi-calendar-fill::before {
  content: "\f1e9"; }

.bi-calendar-minus-fill::before {
  content: "\f1ea"; }

.bi-calendar-minus::before {
  content: "\f1eb"; }

.bi-calendar-month-fill::before {
  content: "\f1ec"; }

.bi-calendar-month::before {
  content: "\f1ed"; }

.bi-calendar-plus-fill::before {
  content: "\f1ee"; }

.bi-calendar-plus::before {
  content: "\f1ef"; }

.bi-calendar-range-fill::before {
  content: "\f1f0"; }

.bi-calendar-range::before {
  content: "\f1f1"; }

.bi-calendar-week-fill::before {
  content: "\f1f2"; }

.bi-calendar-week::before {
  content: "\f1f3"; }

.bi-calendar-x-fill::before {
  content: "\f1f4"; }

.bi-calendar-x::before {
  content: "\f1f5"; }

.bi-calendar::before {
  content: "\f1f6"; }

.bi-calendar2-check-fill::before {
  content: "\f1f7"; }

.bi-calendar2-check::before {
  content: "\f1f8"; }

.bi-calendar2-date-fill::before {
  content: "\f1f9"; }

.bi-calendar2-date::before {
  content: "\f1fa"; }

.bi-calendar2-day-fill::before {
  content: "\f1fb"; }

.bi-calendar2-day::before {
  content: "\f1fc"; }

.bi-calendar2-event-fill::before {
  content: "\f1fd"; }

.bi-calendar2-event::before {
  content: "\f1fe"; }

.bi-calendar2-fill::before {
  content: "\f1ff"; }

.bi-calendar2-minus-fill::before {
  content: "\f200"; }

.bi-calendar2-minus::before {
  content: "\f201"; }

.bi-calendar2-month-fill::before {
  content: "\f202"; }

.bi-calendar2-month::before {
  content: "\f203"; }

.bi-calendar2-plus-fill::before {
  content: "\f204"; }

.bi-calendar2-plus::before {
  content: "\f205"; }

.bi-calendar2-range-fill::before {
  content: "\f206"; }

.bi-calendar2-range::before {
  content: "\f207"; }

.bi-calendar2-week-fill::before {
  content: "\f208"; }

.bi-calendar2-week::before {
  content: "\f209"; }

.bi-calendar2-x-fill::before {
  content: "\f20a"; }

.bi-calendar2-x::before {
  content: "\f20b"; }

.bi-calendar2::before {
  content: "\f20c"; }

.bi-calendar3-event-fill::before {
  content: "\f20d"; }

.bi-calendar3-event::before {
  content: "\f20e"; }

.bi-calendar3-fill::before {
  content: "\f20f"; }

.bi-calendar3-range-fill::before {
  content: "\f210"; }

.bi-calendar3-range::before {
  content: "\f211"; }

.bi-calendar3-week-fill::before {
  content: "\f212"; }

.bi-calendar3-week::before {
  content: "\f213"; }

.bi-calendar3::before {
  content: "\f214"; }

.bi-calendar4-event::before {
  content: "\f215"; }

.bi-calendar4-range::before {
  content: "\f216"; }

.bi-calendar4-week::before {
  content: "\f217"; }

.bi-calendar4::before {
  content: "\f218"; }

.bi-camera-fill::before {
  content: "\f219"; }

.bi-camera-reels-fill::before {
  content: "\f21a"; }

.bi-camera-reels::before {
  content: "\f21b"; }

.bi-camera-video-fill::before {
  content: "\f21c"; }

.bi-camera-video-off-fill::before {
  content: "\f21d"; }

.bi-camera-video-off::before {
  content: "\f21e"; }

.bi-camera-video::before {
  content: "\f21f"; }

.bi-camera::before {
  content: "\f220"; }

.bi-camera2::before {
  content: "\f221"; }

.bi-capslock-fill::before {
  content: "\f222"; }

.bi-capslock::before {
  content: "\f223"; }

.bi-card-checklist::before {
  content: "\f224"; }

.bi-card-heading::before {
  content: "\f225"; }

.bi-card-image::before {
  content: "\f226"; }

.bi-card-list::before {
  content: "\f227"; }

.bi-card-text::before {
  content: "\f228"; }

.bi-caret-down-fill::before {
  content: "\f229"; }

.bi-caret-down-square-fill::before {
  content: "\f22a"; }

.bi-caret-down-square::before {
  content: "\f22b"; }

.bi-caret-down::before {
  content: "\f22c"; }

.bi-caret-left-fill::before {
  content: "\f22d"; }

.bi-caret-left-square-fill::before {
  content: "\f22e"; }

.bi-caret-left-square::before {
  content: "\f22f"; }

.bi-caret-left::before {
  content: "\f230"; }

.bi-caret-right-fill::before {
  content: "\f231"; }

.bi-caret-right-square-fill::before {
  content: "\f232"; }

.bi-caret-right-square::before {
  content: "\f233"; }

.bi-caret-right::before {
  content: "\f234"; }

.bi-caret-up-fill::before {
  content: "\f235"; }

.bi-caret-up-square-fill::before {
  content: "\f236"; }

.bi-caret-up-square::before {
  content: "\f237"; }

.bi-caret-up::before {
  content: "\f238"; }

.bi-cart-check-fill::before {
  content: "\f239"; }

.bi-cart-check::before {
  content: "\f23a"; }

.bi-cart-dash-fill::before {
  content: "\f23b"; }

.bi-cart-dash::before {
  content: "\f23c"; }

.bi-cart-fill::before {
  content: "\f23d"; }

.bi-cart-plus-fill::before {
  content: "\f23e"; }

.bi-cart-plus::before {
  content: "\f23f"; }

.bi-cart-x-fill::before {
  content: "\f240"; }

.bi-cart-x::before {
  content: "\f241"; }

.bi-cart::before {
  content: "\f242"; }

.bi-cart2::before {
  content: "\f243"; }

.bi-cart3::before {
  content: "\f244"; }

.bi-cart4::before {
  content: "\f245"; }

.bi-cash-stack::before {
  content: "\f246"; }

.bi-cash::before {
  content: "\f247"; }

.bi-cast::before {
  content: "\f248"; }

.bi-chat-dots-fill::before {
  content: "\f249"; }

.bi-chat-dots::before {
  content: "\f24a"; }

.bi-chat-fill::before {
  content: "\f24b"; }

.bi-chat-left-dots-fill::before {
  content: "\f24c"; }

.bi-chat-left-dots::before {
  content: "\f24d"; }

.bi-chat-left-fill::before {
  content: "\f24e"; }

.bi-chat-left-quote-fill::before {
  content: "\f24f"; }

.bi-chat-left-quote::before {
  content: "\f250"; }

.bi-chat-left-text-fill::before {
  content: "\f251"; }

.bi-chat-left-text::before {
  content: "\f252"; }

.bi-chat-left::before {
  content: "\f253"; }

.bi-chat-quote-fill::before {
  content: "\f254"; }

.bi-chat-quote::before {
  content: "\f255"; }

.bi-chat-right-dots-fill::before {
  content: "\f256"; }

.bi-chat-right-dots::before {
  content: "\f257"; }

.bi-chat-right-fill::before {
  content: "\f258"; }

.bi-chat-right-quote-fill::before {
  content: "\f259"; }

.bi-chat-right-quote::before {
  content: "\f25a"; }

.bi-chat-right-text-fill::before {
  content: "\f25b"; }

.bi-chat-right-text::before {
  content: "\f25c"; }

.bi-chat-right::before {
  content: "\f25d"; }

.bi-chat-square-dots-fill::before {
  content: "\f25e"; }

.bi-chat-square-dots::before {
  content: "\f25f"; }

.bi-chat-square-fill::before {
  content: "\f260"; }

.bi-chat-square-quote-fill::before {
  content: "\f261"; }

.bi-chat-square-quote::before {
  content: "\f262"; }

.bi-chat-square-text-fill::before {
  content: "\f263"; }

.bi-chat-square-text::before {
  content: "\f264"; }

.bi-chat-square::before {
  content: "\f265"; }

.bi-chat-text-fill::before {
  content: "\f266"; }

.bi-chat-text::before {
  content: "\f267"; }

.bi-chat::before {
  content: "\f268"; }

.bi-check-all::before {
  content: "\f269"; }

.bi-check-circle-fill::before {
  content: "\f26a"; }

.bi-check-circle::before {
  content: "\f26b"; }

.bi-check-square-fill::before {
  content: "\f26c"; }

.bi-check-square::before {
  content: "\f26d"; }

.bi-check::before {
  content: "\f26e"; }

.bi-check2-all::before {
  content: "\f26f"; }

.bi-check2-circle::before {
  content: "\f270"; }

.bi-check2-square::before {
  content: "\f271"; }

.bi-check2::before {
  content: "\f272"; }

.bi-chevron-bar-contract::before {
  content: "\f273"; }

.bi-chevron-bar-down::before {
  content: "\f274"; }

.bi-chevron-bar-expand::before {
  content: "\f275"; }

.bi-chevron-bar-left::before {
  content: "\f276"; }

.bi-chevron-bar-right::before {
  content: "\f277"; }

.bi-chevron-bar-up::before {
  content: "\f278"; }

.bi-chevron-compact-down::before {
  content: "\f279"; }

.bi-chevron-compact-left::before {
  content: "\f27a"; }

.bi-chevron-compact-right::before {
  content: "\f27b"; }

.bi-chevron-compact-up::before {
  content: "\f27c"; }

.bi-chevron-contract::before {
  content: "\f27d"; }

.bi-chevron-double-down::before {
  content: "\f27e"; }

.bi-chevron-double-left::before {
  content: "\f27f"; }

.bi-chevron-double-right::before {
  content: "\f280"; }

.bi-chevron-double-up::before {
  content: "\f281"; }

.bi-chevron-down::before {
  content: "\f282"; }

.bi-chevron-expand::before {
  content: "\f283"; }

.bi-chevron-left::before {
  content: "\f284"; }

.bi-chevron-right::before {
  content: "\f285"; }

.bi-chevron-up::before {
  content: "\f286"; }

.bi-circle-fill::before {
  content: "\f287"; }

.bi-circle-half::before {
  content: "\f288"; }

.bi-circle-square::before {
  content: "\f289"; }

.bi-circle::before {
  content: "\f28a"; }

.bi-clipboard-check::before {
  content: "\f28b"; }

.bi-clipboard-data::before {
  content: "\f28c"; }

.bi-clipboard-minus::before {
  content: "\f28d"; }

.bi-clipboard-plus::before {
  content: "\f28e"; }

.bi-clipboard-x::before {
  content: "\f28f"; }

.bi-clipboard::before {
  content: "\f290"; }

.bi-clock-fill::before {
  content: "\f291"; }

.bi-clock-history::before {
  content: "\f292"; }

.bi-clock::before {
  content: "\f293"; }

.bi-cloud-arrow-down-fill::before {
  content: "\f294"; }

.bi-cloud-arrow-down::before {
  content: "\f295"; }

.bi-cloud-arrow-up-fill::before {
  content: "\f296"; }

.bi-cloud-arrow-up::before {
  content: "\f297"; }

.bi-cloud-check-fill::before {
  content: "\f298"; }

.bi-cloud-check::before {
  content: "\f299"; }

.bi-cloud-download-fill::before {
  content: "\f29a"; }

.bi-cloud-download::before {
  content: "\f29b"; }

.bi-cloud-drizzle-fill::before {
  content: "\f29c"; }

.bi-cloud-drizzle::before {
  content: "\f29d"; }

.bi-cloud-fill::before {
  content: "\f29e"; }

.bi-cloud-fog-fill::before {
  content: "\f29f"; }

.bi-cloud-fog::before {
  content: "\f2a0"; }

.bi-cloud-fog2-fill::before {
  content: "\f2a1"; }

.bi-cloud-fog2::before {
  content: "\f2a2"; }

.bi-cloud-hail-fill::before {
  content: "\f2a3"; }

.bi-cloud-hail::before {
  content: "\f2a4"; }

.bi-cloud-haze-1::before {
  content: "\f2a5"; }

.bi-cloud-haze-fill::before {
  content: "\f2a6"; }

.bi-cloud-haze::before {
  content: "\f2a7"; }

.bi-cloud-haze2-fill::before {
  content: "\f2a8"; }

.bi-cloud-lightning-fill::before {
  content: "\f2a9"; }

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa"; }

.bi-cloud-lightning-rain::before {
  content: "\f2ab"; }

.bi-cloud-lightning::before {
  content: "\f2ac"; }

.bi-cloud-minus-fill::before {
  content: "\f2ad"; }

.bi-cloud-minus::before {
  content: "\f2ae"; }

.bi-cloud-moon-fill::before {
  content: "\f2af"; }

.bi-cloud-moon::before {
  content: "\f2b0"; }

.bi-cloud-plus-fill::before {
  content: "\f2b1"; }

.bi-cloud-plus::before {
  content: "\f2b2"; }

.bi-cloud-rain-fill::before {
  content: "\f2b3"; }

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4"; }

.bi-cloud-rain-heavy::before {
  content: "\f2b5"; }

.bi-cloud-rain::before {
  content: "\f2b6"; }

.bi-cloud-slash-fill::before {
  content: "\f2b7"; }

.bi-cloud-slash::before {
  content: "\f2b8"; }

.bi-cloud-sleet-fill::before {
  content: "\f2b9"; }

.bi-cloud-sleet::before {
  content: "\f2ba"; }

.bi-cloud-snow-fill::before {
  content: "\f2bb"; }

.bi-cloud-snow::before {
  content: "\f2bc"; }

.bi-cloud-sun-fill::before {
  content: "\f2bd"; }

.bi-cloud-sun::before {
  content: "\f2be"; }

.bi-cloud-upload-fill::before {
  content: "\f2bf"; }

.bi-cloud-upload::before {
  content: "\f2c0"; }

.bi-cloud::before {
  content: "\f2c1"; }

.bi-clouds-fill::before {
  content: "\f2c2"; }

.bi-clouds::before {
  content: "\f2c3"; }

.bi-cloudy-fill::before {
  content: "\f2c4"; }

.bi-cloudy::before {
  content: "\f2c5"; }

.bi-code-slash::before {
  content: "\f2c6"; }

.bi-code-square::before {
  content: "\f2c7"; }

.bi-code::before {
  content: "\f2c8"; }

.bi-collection-fill::before {
  content: "\f2c9"; }

.bi-collection-play-fill::before {
  content: "\f2ca"; }

.bi-collection-play::before {
  content: "\f2cb"; }

.bi-collection::before {
  content: "\f2cc"; }

.bi-columns-gap::before {
  content: "\f2cd"; }

.bi-columns::before {
  content: "\f2ce"; }

.bi-command::before {
  content: "\f2cf"; }

.bi-compass-fill::before {
  content: "\f2d0"; }

.bi-compass::before {
  content: "\f2d1"; }

.bi-cone-striped::before {
  content: "\f2d2"; }

.bi-cone::before {
  content: "\f2d3"; }

.bi-controller::before {
  content: "\f2d4"; }

.bi-cpu-fill::before {
  content: "\f2d5"; }

.bi-cpu::before {
  content: "\f2d6"; }

.bi-credit-card-2-back-fill::before {
  content: "\f2d7"; }

.bi-credit-card-2-back::before {
  content: "\f2d8"; }

.bi-credit-card-2-front-fill::before {
  content: "\f2d9"; }

.bi-credit-card-2-front::before {
  content: "\f2da"; }

.bi-credit-card-fill::before {
  content: "\f2db"; }

.bi-credit-card::before {
  content: "\f2dc"; }

.bi-crop::before {
  content: "\f2dd"; }

.bi-cup-fill::before {
  content: "\f2de"; }

.bi-cup-straw::before {
  content: "\f2df"; }

.bi-cup::before {
  content: "\f2e0"; }

.bi-cursor-fill::before {
  content: "\f2e1"; }

.bi-cursor-text::before {
  content: "\f2e2"; }

.bi-cursor::before {
  content: "\f2e3"; }

.bi-dash-circle-dotted::before {
  content: "\f2e4"; }

.bi-dash-circle-fill::before {
  content: "\f2e5"; }

.bi-dash-circle::before {
  content: "\f2e6"; }

.bi-dash-square-dotted::before {
  content: "\f2e7"; }

.bi-dash-square-fill::before {
  content: "\f2e8"; }

.bi-dash-square::before {
  content: "\f2e9"; }

.bi-dash::before {
  content: "\f2ea"; }

.bi-diagram-2-fill::before {
  content: "\f2eb"; }

.bi-diagram-2::before {
  content: "\f2ec"; }

.bi-diagram-3-fill::before {
  content: "\f2ed"; }

.bi-diagram-3::before {
  content: "\f2ee"; }

.bi-diamond-fill::before {
  content: "\f2ef"; }

.bi-diamond-half::before {
  content: "\f2f0"; }

.bi-diamond::before {
  content: "\f2f1"; }

.bi-dice-1-fill::before {
  content: "\f2f2"; }

.bi-dice-1::before {
  content: "\f2f3"; }

.bi-dice-2-fill::before {
  content: "\f2f4"; }

.bi-dice-2::before {
  content: "\f2f5"; }

.bi-dice-3-fill::before {
  content: "\f2f6"; }

.bi-dice-3::before {
  content: "\f2f7"; }

.bi-dice-4-fill::before {
  content: "\f2f8"; }

.bi-dice-4::before {
  content: "\f2f9"; }

.bi-dice-5-fill::before {
  content: "\f2fa"; }

.bi-dice-5::before {
  content: "\f2fb"; }

.bi-dice-6-fill::before {
  content: "\f2fc"; }

.bi-dice-6::before {
  content: "\f2fd"; }

.bi-disc-fill::before {
  content: "\f2fe"; }

.bi-disc::before {
  content: "\f2ff"; }

.bi-discord::before {
  content: "\f300"; }

.bi-display-fill::before {
  content: "\f301"; }

.bi-display::before {
  content: "\f302"; }

.bi-distribute-horizontal::before {
  content: "\f303"; }

.bi-distribute-vertical::before {
  content: "\f304"; }

.bi-door-closed-fill::before {
  content: "\f305"; }

.bi-door-closed::before {
  content: "\f306"; }

.bi-door-open-fill::before {
  content: "\f307"; }

.bi-door-open::before {
  content: "\f308"; }

.bi-dot::before {
  content: "\f309"; }

.bi-download::before {
  content: "\f30a"; }

.bi-droplet-fill::before {
  content: "\f30b"; }

.bi-droplet-half::before {
  content: "\f30c"; }

.bi-droplet::before {
  content: "\f30d"; }

.bi-earbuds::before {
  content: "\f30e"; }

.bi-easel-fill::before {
  content: "\f30f"; }

.bi-easel::before {
  content: "\f310"; }

.bi-egg-fill::before {
  content: "\f311"; }

.bi-egg-fried::before {
  content: "\f312"; }

.bi-egg::before {
  content: "\f313"; }

.bi-eject-fill::before {
  content: "\f314"; }

.bi-eject::before {
  content: "\f315"; }

.bi-emoji-angry-fill::before {
  content: "\f316"; }

.bi-emoji-angry::before {
  content: "\f317"; }

.bi-emoji-dizzy-fill::before {
  content: "\f318"; }

.bi-emoji-dizzy::before {
  content: "\f319"; }

.bi-emoji-expressionless-fill::before {
  content: "\f31a"; }

.bi-emoji-expressionless::before {
  content: "\f31b"; }

.bi-emoji-frown-fill::before {
  content: "\f31c"; }

.bi-emoji-frown::before {
  content: "\f31d"; }

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e"; }

.bi-emoji-heart-eyes::before {
  content: "\f31f"; }

.bi-emoji-laughing-fill::before {
  content: "\f320"; }

.bi-emoji-laughing::before {
  content: "\f321"; }

.bi-emoji-neutral-fill::before {
  content: "\f322"; }

.bi-emoji-neutral::before {
  content: "\f323"; }

.bi-emoji-smile-fill::before {
  content: "\f324"; }

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325"; }

.bi-emoji-smile-upside-down::before {
  content: "\f326"; }

.bi-emoji-smile::before {
  content: "\f327"; }

.bi-emoji-sunglasses-fill::before {
  content: "\f328"; }

.bi-emoji-sunglasses::before {
  content: "\f329"; }

.bi-emoji-wink-fill::before {
  content: "\f32a"; }

.bi-emoji-wink::before {
  content: "\f32b"; }

.bi-envelope-fill::before {
  content: "\f32c"; }

.bi-envelope-open-fill::before {
  content: "\f32d"; }

.bi-envelope-open::before {
  content: "\f32e"; }

.bi-envelope::before {
  content: "\f32f"; }

.bi-eraser-fill::before {
  content: "\f330"; }

.bi-eraser::before {
  content: "\f331"; }

.bi-exclamation-circle-fill::before {
  content: "\f332"; }

.bi-exclamation-circle::before {
  content: "\f333"; }

.bi-exclamation-diamond-fill::before {
  content: "\f334"; }

.bi-exclamation-diamond::before {
  content: "\f335"; }

.bi-exclamation-octagon-fill::before {
  content: "\f336"; }

.bi-exclamation-octagon::before {
  content: "\f337"; }

.bi-exclamation-square-fill::before {
  content: "\f338"; }

.bi-exclamation-square::before {
  content: "\f339"; }

.bi-exclamation-triangle-fill::before {
  content: "\f33a"; }

.bi-exclamation-triangle::before {
  content: "\f33b"; }

.bi-exclamation::before {
  content: "\f33c"; }

.bi-exclude::before {
  content: "\f33d"; }

.bi-eye-fill::before {
  content: "\f33e"; }

.bi-eye-slash-fill::before {
  content: "\f33f"; }

.bi-eye-slash::before {
  content: "\f340"; }

.bi-eye::before {
  content: "\f341"; }

.bi-eyedropper::before {
  content: "\f342"; }

.bi-eyeglasses::before {
  content: "\f343"; }

.bi-facebook::before {
  content: "\f344"; }

.bi-file-arrow-down-fill::before {
  content: "\f345"; }

.bi-file-arrow-down::before {
  content: "\f346"; }

.bi-file-arrow-up-fill::before {
  content: "\f347"; }

.bi-file-arrow-up::before {
  content: "\f348"; }

.bi-file-bar-graph-fill::before {
  content: "\f349"; }

.bi-file-bar-graph::before {
  content: "\f34a"; }

.bi-file-binary-fill::before {
  content: "\f34b"; }

.bi-file-binary::before {
  content: "\f34c"; }

.bi-file-break-fill::before {
  content: "\f34d"; }

.bi-file-break::before {
  content: "\f34e"; }

.bi-file-check-fill::before {
  content: "\f34f"; }

.bi-file-check::before {
  content: "\f350"; }

.bi-file-code-fill::before {
  content: "\f351"; }

.bi-file-code::before {
  content: "\f352"; }

.bi-file-diff-fill::before {
  content: "\f353"; }

.bi-file-diff::before {
  content: "\f354"; }

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355"; }

.bi-file-earmark-arrow-down::before {
  content: "\f356"; }

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357"; }

.bi-file-earmark-arrow-up::before {
  content: "\f358"; }

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359"; }

.bi-file-earmark-bar-graph::before {
  content: "\f35a"; }

.bi-file-earmark-binary-fill::before {
  content: "\f35b"; }

.bi-file-earmark-binary::before {
  content: "\f35c"; }

.bi-file-earmark-break-fill::before {
  content: "\f35d"; }

.bi-file-earmark-break::before {
  content: "\f35e"; }

.bi-file-earmark-check-fill::before {
  content: "\f35f"; }

.bi-file-earmark-check::before {
  content: "\f360"; }

.bi-file-earmark-code-fill::before {
  content: "\f361"; }

.bi-file-earmark-code::before {
  content: "\f362"; }

.bi-file-earmark-diff-fill::before {
  content: "\f363"; }

.bi-file-earmark-diff::before {
  content: "\f364"; }

.bi-file-earmark-easel-fill::before {
  content: "\f365"; }

.bi-file-earmark-easel::before {
  content: "\f366"; }

.bi-file-earmark-excel-fill::before {
  content: "\f367"; }

.bi-file-earmark-excel::before {
  content: "\f368"; }

.bi-file-earmark-fill::before {
  content: "\f369"; }

.bi-file-earmark-font-fill::before {
  content: "\f36a"; }

.bi-file-earmark-font::before {
  content: "\f36b"; }

.bi-file-earmark-image-fill::before {
  content: "\f36c"; }

.bi-file-earmark-image::before {
  content: "\f36d"; }

.bi-file-earmark-lock-fill::before {
  content: "\f36e"; }

.bi-file-earmark-lock::before {
  content: "\f36f"; }

.bi-file-earmark-lock2-fill::before {
  content: "\f370"; }

.bi-file-earmark-lock2::before {
  content: "\f371"; }

.bi-file-earmark-medical-fill::before {
  content: "\f372"; }

.bi-file-earmark-medical::before {
  content: "\f373"; }

.bi-file-earmark-minus-fill::before {
  content: "\f374"; }

.bi-file-earmark-minus::before {
  content: "\f375"; }

.bi-file-earmark-music-fill::before {
  content: "\f376"; }

.bi-file-earmark-music::before {
  content: "\f377"; }

.bi-file-earmark-person-fill::before {
  content: "\f378"; }

.bi-file-earmark-person::before {
  content: "\f379"; }

.bi-file-earmark-play-fill::before {
  content: "\f37a"; }

.bi-file-earmark-play::before {
  content: "\f37b"; }

.bi-file-earmark-plus-fill::before {
  content: "\f37c"; }

.bi-file-earmark-plus::before {
  content: "\f37d"; }

.bi-file-earmark-post-fill::before {
  content: "\f37e"; }

.bi-file-earmark-post::before {
  content: "\f37f"; }

.bi-file-earmark-ppt-fill::before {
  content: "\f380"; }

.bi-file-earmark-ppt::before {
  content: "\f381"; }

.bi-file-earmark-richtext-fill::before {
  content: "\f382"; }

.bi-file-earmark-richtext::before {
  content: "\f383"; }

.bi-file-earmark-ruled-fill::before {
  content: "\f384"; }

.bi-file-earmark-ruled::before {
  content: "\f385"; }

.bi-file-earmark-slides-fill::before {
  content: "\f386"; }

.bi-file-earmark-slides::before {
  content: "\f387"; }

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388"; }

.bi-file-earmark-spreadsheet::before {
  content: "\f389"; }

.bi-file-earmark-text-fill::before {
  content: "\f38a"; }

.bi-file-earmark-text::before {
  content: "\f38b"; }

.bi-file-earmark-word-fill::before {
  content: "\f38c"; }

.bi-file-earmark-word::before {
  content: "\f38d"; }

.bi-file-earmark-x-fill::before {
  content: "\f38e"; }

.bi-file-earmark-x::before {
  content: "\f38f"; }

.bi-file-earmark-zip-fill::before {
  content: "\f390"; }

.bi-file-earmark-zip::before {
  content: "\f391"; }

.bi-file-earmark::before {
  content: "\f392"; }

.bi-file-easel-fill::before {
  content: "\f393"; }

.bi-file-easel::before {
  content: "\f394"; }

.bi-file-excel-fill::before {
  content: "\f395"; }

.bi-file-excel::before {
  content: "\f396"; }

.bi-file-fill::before {
  content: "\f397"; }

.bi-file-font-fill::before {
  content: "\f398"; }

.bi-file-font::before {
  content: "\f399"; }

.bi-file-image-fill::before {
  content: "\f39a"; }

.bi-file-image::before {
  content: "\f39b"; }

.bi-file-lock-fill::before {
  content: "\f39c"; }

.bi-file-lock::before {
  content: "\f39d"; }

.bi-file-lock2-fill::before {
  content: "\f39e"; }

.bi-file-lock2::before {
  content: "\f39f"; }

.bi-file-medical-fill::before {
  content: "\f3a0"; }

.bi-file-medical::before {
  content: "\f3a1"; }

.bi-file-minus-fill::before {
  content: "\f3a2"; }

.bi-file-minus::before {
  content: "\f3a3"; }

.bi-file-music-fill::before {
  content: "\f3a4"; }

.bi-file-music::before {
  content: "\f3a5"; }

.bi-file-person-fill::before {
  content: "\f3a6"; }

.bi-file-person::before {
  content: "\f3a7"; }

.bi-file-play-fill::before {
  content: "\f3a8"; }

.bi-file-play::before {
  content: "\f3a9"; }

.bi-file-plus-fill::before {
  content: "\f3aa"; }

.bi-file-plus::before {
  content: "\f3ab"; }

.bi-file-post-fill::before {
  content: "\f3ac"; }

.bi-file-post::before {
  content: "\f3ad"; }

.bi-file-ppt-fill::before {
  content: "\f3ae"; }

.bi-file-ppt::before {
  content: "\f3af"; }

.bi-file-richtext-fill::before {
  content: "\f3b0"; }

.bi-file-richtext::before {
  content: "\f3b1"; }

.bi-file-ruled-fill::before {
  content: "\f3b2"; }

.bi-file-ruled::before {
  content: "\f3b3"; }

.bi-file-slides-fill::before {
  content: "\f3b4"; }

.bi-file-slides::before {
  content: "\f3b5"; }

.bi-file-spreadsheet-fill::before {
  content: "\f3b6"; }

.bi-file-spreadsheet::before {
  content: "\f3b7"; }

.bi-file-text-fill::before {
  content: "\f3b8"; }

.bi-file-text::before {
  content: "\f3b9"; }

.bi-file-word-fill::before {
  content: "\f3ba"; }

.bi-file-word::before {
  content: "\f3bb"; }

.bi-file-x-fill::before {
  content: "\f3bc"; }

.bi-file-x::before {
  content: "\f3bd"; }

.bi-file-zip-fill::before {
  content: "\f3be"; }

.bi-file-zip::before {
  content: "\f3bf"; }

.bi-file::before {
  content: "\f3c0"; }

.bi-files-alt::before {
  content: "\f3c1"; }

.bi-files::before {
  content: "\f3c2"; }

.bi-film::before {
  content: "\f3c3"; }

.bi-filter-circle-fill::before {
  content: "\f3c4"; }

.bi-filter-circle::before {
  content: "\f3c5"; }

.bi-filter-left::before {
  content: "\f3c6"; }

.bi-filter-right::before {
  content: "\f3c7"; }

.bi-filter-square-fill::before {
  content: "\f3c8"; }

.bi-filter-square::before {
  content: "\f3c9"; }

.bi-filter::before {
  content: "\f3ca"; }

.bi-flag-fill::before {
  content: "\f3cb"; }

.bi-flag::before {
  content: "\f3cc"; }

.bi-flower1::before {
  content: "\f3cd"; }

.bi-flower2::before {
  content: "\f3ce"; }

.bi-flower3::before {
  content: "\f3cf"; }

.bi-folder-check::before {
  content: "\f3d0"; }

.bi-folder-fill::before {
  content: "\f3d1"; }

.bi-folder-minus::before {
  content: "\f3d2"; }

.bi-folder-plus::before {
  content: "\f3d3"; }

.bi-folder-symlink-fill::before {
  content: "\f3d4"; }

.bi-folder-symlink::before {
  content: "\f3d5"; }

.bi-folder-x::before {
  content: "\f3d6"; }

.bi-folder::before {
  content: "\f3d7"; }

.bi-folder2-open::before {
  content: "\f3d8"; }

.bi-folder2::before {
  content: "\f3d9"; }

.bi-fonts::before {
  content: "\f3da"; }

.bi-forward-fill::before {
  content: "\f3db"; }

.bi-forward::before {
  content: "\f3dc"; }

.bi-front::before {
  content: "\f3dd"; }

.bi-fullscreen-exit::before {
  content: "\f3de"; }

.bi-fullscreen::before {
  content: "\f3df"; }

.bi-funnel-fill::before {
  content: "\f3e0"; }

.bi-funnel::before {
  content: "\f3e1"; }

.bi-gear-fill::before {
  content: "\f3e2"; }

.bi-gear-wide-connected::before {
  content: "\f3e3"; }

.bi-gear-wide::before {
  content: "\f3e4"; }

.bi-gear::before {
  content: "\f3e5"; }

.bi-gem::before {
  content: "\f3e6"; }

.bi-geo-alt-fill::before {
  content: "\f3e7"; }

.bi-geo-alt::before {
  content: "\f3e8"; }

.bi-geo-fill::before {
  content: "\f3e9"; }

.bi-geo::before {
  content: "\f3ea"; }

.bi-gift-fill::before {
  content: "\f3eb"; }

.bi-gift::before {
  content: "\f3ec"; }

.bi-github::before {
  content: "\f3ed"; }

.bi-globe::before {
  content: "\f3ee"; }

.bi-globe2::before {
  content: "\f3ef"; }

.bi-google::before {
  content: "\f3f0"; }

.bi-graph-down::before {
  content: "\f3f1"; }

.bi-graph-up::before {
  content: "\f3f2"; }

.bi-grid-1x2-fill::before {
  content: "\f3f3"; }

.bi-grid-1x2::before {
  content: "\f3f4"; }

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5"; }

.bi-grid-3x2-gap::before {
  content: "\f3f6"; }

.bi-grid-3x2::before {
  content: "\f3f7"; }

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8"; }

.bi-grid-3x3-gap::before {
  content: "\f3f9"; }

.bi-grid-3x3::before {
  content: "\f3fa"; }

.bi-grid-fill::before {
  content: "\f3fb"; }

.bi-grid::before {
  content: "\f3fc"; }

.bi-grip-horizontal::before {
  content: "\f3fd"; }

.bi-grip-vertical::before {
  content: "\f3fe"; }

.bi-hammer::before {
  content: "\f3ff"; }

.bi-hand-index-fill::before {
  content: "\f400"; }

.bi-hand-index-thumb-fill::before {
  content: "\f401"; }

.bi-hand-index-thumb::before {
  content: "\f402"; }

.bi-hand-index::before {
  content: "\f403"; }

.bi-hand-thumbs-down-fill::before {
  content: "\f404"; }

.bi-hand-thumbs-down::before {
  content: "\f405"; }

.bi-hand-thumbs-up-fill::before {
  content: "\f406"; }

.bi-hand-thumbs-up::before {
  content: "\f407"; }

.bi-handbag-fill::before {
  content: "\f408"; }

.bi-handbag::before {
  content: "\f409"; }

.bi-hash::before {
  content: "\f40a"; }

.bi-hdd-fill::before {
  content: "\f40b"; }

.bi-hdd-network-fill::before {
  content: "\f40c"; }

.bi-hdd-network::before {
  content: "\f40d"; }

.bi-hdd-rack-fill::before {
  content: "\f40e"; }

.bi-hdd-rack::before {
  content: "\f40f"; }

.bi-hdd-stack-fill::before {
  content: "\f410"; }

.bi-hdd-stack::before {
  content: "\f411"; }

.bi-hdd::before {
  content: "\f412"; }

.bi-headphones::before {
  content: "\f413"; }

.bi-headset::before {
  content: "\f414"; }

.bi-heart-fill::before {
  content: "\f415"; }

.bi-heart-half::before {
  content: "\f416"; }

.bi-heart::before {
  content: "\f417"; }

.bi-heptagon-fill::before {
  content: "\f418"; }

.bi-heptagon-half::before {
  content: "\f419"; }

.bi-heptagon::before {
  content: "\f41a"; }

.bi-hexagon-fill::before {
  content: "\f41b"; }

.bi-hexagon-half::before {
  content: "\f41c"; }

.bi-hexagon::before {
  content: "\f41d"; }

.bi-hourglass-bottom::before {
  content: "\f41e"; }

.bi-hourglass-split::before {
  content: "\f41f"; }

.bi-hourglass-top::before {
  content: "\f420"; }

.bi-hourglass::before {
  content: "\f421"; }

.bi-house-door-fill::before {
  content: "\f422"; }

.bi-house-door::before {
  content: "\f423"; }

.bi-house-fill::before {
  content: "\f424"; }

.bi-house::before {
  content: "\f425"; }

.bi-hr::before {
  content: "\f426"; }

.bi-hurricane::before {
  content: "\f427"; }

.bi-image-alt::before {
  content: "\f428"; }

.bi-image-fill::before {
  content: "\f429"; }

.bi-image::before {
  content: "\f42a"; }

.bi-images::before {
  content: "\f42b"; }

.bi-inbox-fill::before {
  content: "\f42c"; }

.bi-inbox::before {
  content: "\f42d"; }

.bi-inboxes-fill::before {
  content: "\f42e"; }

.bi-inboxes::before {
  content: "\f42f"; }

.bi-info-circle-fill::before {
  content: "\f430"; }

.bi-info-circle::before {
  content: "\f431"; }

.bi-info-square-fill::before {
  content: "\f432"; }

.bi-info-square::before {
  content: "\f433"; }

.bi-info::before {
  content: "\f434"; }

.bi-input-cursor-text::before {
  content: "\f435"; }

.bi-input-cursor::before {
  content: "\f436"; }

.bi-instagram::before {
  content: "\f437"; }

.bi-intersect::before {
  content: "\f438"; }

.bi-journal-album::before {
  content: "\f439"; }

.bi-journal-arrow-down::before {
  content: "\f43a"; }

.bi-journal-arrow-up::before {
  content: "\f43b"; }

.bi-journal-bookmark-fill::before {
  content: "\f43c"; }

.bi-journal-bookmark::before {
  content: "\f43d"; }

.bi-journal-check::before {
  content: "\f43e"; }

.bi-journal-code::before {
  content: "\f43f"; }

.bi-journal-medical::before {
  content: "\f440"; }

.bi-journal-minus::before {
  content: "\f441"; }

.bi-journal-plus::before {
  content: "\f442"; }

.bi-journal-richtext::before {
  content: "\f443"; }

.bi-journal-text::before {
  content: "\f444"; }

.bi-journal-x::before {
  content: "\f445"; }

.bi-journal::before {
  content: "\f446"; }

.bi-journals::before {
  content: "\f447"; }

.bi-joystick::before {
  content: "\f448"; }

.bi-justify-left::before {
  content: "\f449"; }

.bi-justify-right::before {
  content: "\f44a"; }

.bi-justify::before {
  content: "\f44b"; }

.bi-kanban-fill::before {
  content: "\f44c"; }

.bi-kanban::before {
  content: "\f44d"; }

.bi-key-fill::before {
  content: "\f44e"; }

.bi-key::before {
  content: "\f44f"; }

.bi-keyboard-fill::before {
  content: "\f450"; }

.bi-keyboard::before {
  content: "\f451"; }

.bi-ladder::before {
  content: "\f452"; }

.bi-lamp-fill::before {
  content: "\f453"; }

.bi-lamp::before {
  content: "\f454"; }

.bi-laptop-fill::before {
  content: "\f455"; }

.bi-laptop::before {
  content: "\f456"; }

.bi-layer-backward::before {
  content: "\f457"; }

.bi-layer-forward::before {
  content: "\f458"; }

.bi-layers-fill::before {
  content: "\f459"; }

.bi-layers-half::before {
  content: "\f45a"; }

.bi-layers::before {
  content: "\f45b"; }

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c"; }

.bi-layout-sidebar-inset::before {
  content: "\f45d"; }

.bi-layout-sidebar-reverse::before {
  content: "\f45e"; }

.bi-layout-sidebar::before {
  content: "\f45f"; }

.bi-layout-split::before {
  content: "\f460"; }

.bi-layout-text-sidebar-reverse::before {
  content: "\f461"; }

.bi-layout-text-sidebar::before {
  content: "\f462"; }

.bi-layout-text-window-reverse::before {
  content: "\f463"; }

.bi-layout-text-window::before {
  content: "\f464"; }

.bi-layout-three-columns::before {
  content: "\f465"; }

.bi-layout-wtf::before {
  content: "\f466"; }

.bi-life-preserver::before {
  content: "\f467"; }

.bi-lightbulb-fill::before {
  content: "\f468"; }

.bi-lightbulb-off-fill::before {
  content: "\f469"; }

.bi-lightbulb-off::before {
  content: "\f46a"; }

.bi-lightbulb::before {
  content: "\f46b"; }

.bi-lightning-charge-fill::before {
  content: "\f46c"; }

.bi-lightning-charge::before {
  content: "\f46d"; }

.bi-lightning-fill::before {
  content: "\f46e"; }

.bi-lightning::before {
  content: "\f46f"; }

.bi-link-45deg::before {
  content: "\f470"; }

.bi-link::before {
  content: "\f471"; }

.bi-linkedin::before {
  content: "\f472"; }

.bi-list-check::before {
  content: "\f473"; }

.bi-list-nested::before {
  content: "\f474"; }

.bi-list-ol::before {
  content: "\f475"; }

.bi-list-stars::before {
  content: "\f476"; }

.bi-list-task::before {
  content: "\f477"; }

.bi-list-ul::before {
  content: "\f478"; }

.bi-list::before {
  content: "\f479"; }

.bi-lock-fill::before {
  content: "\f47a"; }

.bi-lock::before {
  content: "\f47b"; }

.bi-mailbox::before {
  content: "\f47c"; }

.bi-mailbox2::before {
  content: "\f47d"; }

.bi-map-fill::before {
  content: "\f47e"; }

.bi-map::before {
  content: "\f47f"; }

.bi-markdown-fill::before {
  content: "\f480"; }

.bi-markdown::before {
  content: "\f481"; }

.bi-mask::before {
  content: "\f482"; }

.bi-megaphone-fill::before {
  content: "\f483"; }

.bi-megaphone::before {
  content: "\f484"; }

.bi-menu-app-fill::before {
  content: "\f485"; }

.bi-menu-app::before {
  content: "\f486"; }

.bi-menu-button-fill::before {
  content: "\f487"; }

.bi-menu-button-wide-fill::before {
  content: "\f488"; }

.bi-menu-button-wide::before {
  content: "\f489"; }

.bi-menu-button::before {
  content: "\f48a"; }

.bi-menu-down::before {
  content: "\f48b"; }

.bi-menu-up::before {
  content: "\f48c"; }

.bi-mic-fill::before {
  content: "\f48d"; }

.bi-mic-mute-fill::before {
  content: "\f48e"; }

.bi-mic-mute::before {
  content: "\f48f"; }

.bi-mic::before {
  content: "\f490"; }

.bi-minecart-loaded::before {
  content: "\f491"; }

.bi-minecart::before {
  content: "\f492"; }

.bi-moisture::before {
  content: "\f493"; }

.bi-moon-fill::before {
  content: "\f494"; }

.bi-moon-stars-fill::before {
  content: "\f495"; }

.bi-moon-stars::before {
  content: "\f496"; }

.bi-moon::before {
  content: "\f497"; }

.bi-mouse-fill::before {
  content: "\f498"; }

.bi-mouse::before {
  content: "\f499"; }

.bi-mouse2-fill::before {
  content: "\f49a"; }

.bi-mouse2::before {
  content: "\f49b"; }

.bi-mouse3-fill::before {
  content: "\f49c"; }

.bi-mouse3::before {
  content: "\f49d"; }

.bi-music-note-beamed::before {
  content: "\f49e"; }

.bi-music-note-list::before {
  content: "\f49f"; }

.bi-music-note::before {
  content: "\f4a0"; }

.bi-music-player-fill::before {
  content: "\f4a1"; }

.bi-music-player::before {
  content: "\f4a2"; }

.bi-newspaper::before {
  content: "\f4a3"; }

.bi-node-minus-fill::before {
  content: "\f4a4"; }

.bi-node-minus::before {
  content: "\f4a5"; }

.bi-node-plus-fill::before {
  content: "\f4a6"; }

.bi-node-plus::before {
  content: "\f4a7"; }

.bi-nut-fill::before {
  content: "\f4a8"; }

.bi-nut::before {
  content: "\f4a9"; }

.bi-octagon-fill::before {
  content: "\f4aa"; }

.bi-octagon-half::before {
  content: "\f4ab"; }

.bi-octagon::before {
  content: "\f4ac"; }

.bi-option::before {
  content: "\f4ad"; }

.bi-outlet::before {
  content: "\f4ae"; }

.bi-paint-bucket::before {
  content: "\f4af"; }

.bi-palette-fill::before {
  content: "\f4b0"; }

.bi-palette::before {
  content: "\f4b1"; }

.bi-palette2::before {
  content: "\f4b2"; }

.bi-paperclip::before {
  content: "\f4b3"; }

.bi-paragraph::before {
  content: "\f4b4"; }

.bi-patch-check-fill::before {
  content: "\f4b5"; }

.bi-patch-check::before {
  content: "\f4b6"; }

.bi-patch-exclamation-fill::before {
  content: "\f4b7"; }

.bi-patch-exclamation::before {
  content: "\f4b8"; }

.bi-patch-minus-fill::before {
  content: "\f4b9"; }

.bi-patch-minus::before {
  content: "\f4ba"; }

.bi-patch-plus-fill::before {
  content: "\f4bb"; }

.bi-patch-plus::before {
  content: "\f4bc"; }

.bi-patch-question-fill::before {
  content: "\f4bd"; }

.bi-patch-question::before {
  content: "\f4be"; }

.bi-pause-btn-fill::before {
  content: "\f4bf"; }

.bi-pause-btn::before {
  content: "\f4c0"; }

.bi-pause-circle-fill::before {
  content: "\f4c1"; }

.bi-pause-circle::before {
  content: "\f4c2"; }

.bi-pause-fill::before {
  content: "\f4c3"; }

.bi-pause::before {
  content: "\f4c4"; }

.bi-peace-fill::before {
  content: "\f4c5"; }

.bi-peace::before {
  content: "\f4c6"; }

.bi-pen-fill::before {
  content: "\f4c7"; }

.bi-pen::before {
  content: "\f4c8"; }

.bi-pencil-fill::before {
  content: "\f4c9"; }

.bi-pencil-square::before {
  content: "\f4ca"; }

.bi-pencil::before {
  content: "\f4cb"; }

.bi-pentagon-fill::before {
  content: "\f4cc"; }

.bi-pentagon-half::before {
  content: "\f4cd"; }

.bi-pentagon::before {
  content: "\f4ce"; }

.bi-people-fill::before {
  content: "\f4cf"; }

.bi-people::before {
  content: "\f4d0"; }

.bi-percent::before {
  content: "\f4d1"; }

.bi-person-badge-fill::before {
  content: "\f4d2"; }

.bi-person-badge::before {
  content: "\f4d3"; }

.bi-person-bounding-box::before {
  content: "\f4d4"; }

.bi-person-check-fill::before {
  content: "\f4d5"; }

.bi-person-check::before {
  content: "\f4d6"; }

.bi-person-circle::before {
  content: "\f4d7"; }

.bi-person-dash-fill::before {
  content: "\f4d8"; }

.bi-person-dash::before {
  content: "\f4d9"; }

.bi-person-fill::before {
  content: "\f4da"; }

.bi-person-lines-fill::before {
  content: "\f4db"; }

.bi-person-plus-fill::before {
  content: "\f4dc"; }

.bi-person-plus::before {
  content: "\f4dd"; }

.bi-person-square::before {
  content: "\f4de"; }

.bi-person-x-fill::before {
  content: "\f4df"; }

.bi-person-x::before {
  content: "\f4e0"; }

.bi-person::before {
  content: "\f4e1"; }

.bi-phone-fill::before {
  content: "\f4e2"; }

.bi-phone-landscape-fill::before {
  content: "\f4e3"; }

.bi-phone-landscape::before {
  content: "\f4e4"; }

.bi-phone-vibrate-fill::before {
  content: "\f4e5"; }

.bi-phone-vibrate::before {
  content: "\f4e6"; }

.bi-phone::before {
  content: "\f4e7"; }

.bi-pie-chart-fill::before {
  content: "\f4e8"; }

.bi-pie-chart::before {
  content: "\f4e9"; }

.bi-pin-angle-fill::before {
  content: "\f4ea"; }

.bi-pin-angle::before {
  content: "\f4eb"; }

.bi-pin-fill::before {
  content: "\f4ec"; }

.bi-pin::before {
  content: "\f4ed"; }

.bi-pip-fill::before {
  content: "\f4ee"; }

.bi-pip::before {
  content: "\f4ef"; }

.bi-play-btn-fill::before {
  content: "\f4f0"; }

.bi-play-btn::before {
  content: "\f4f1"; }

.bi-play-circle-fill::before {
  content: "\f4f2"; }

.bi-play-circle::before {
  content: "\f4f3"; }

.bi-play-fill::before {
  content: "\f4f4"; }

.bi-play::before {
  content: "\f4f5"; }

.bi-plug-fill::before {
  content: "\f4f6"; }

.bi-plug::before {
  content: "\f4f7"; }

.bi-plus-circle-dotted::before {
  content: "\f4f8"; }

.bi-plus-circle-fill::before {
  content: "\f4f9"; }

.bi-plus-circle::before {
  content: "\f4fa"; }

.bi-plus-square-dotted::before {
  content: "\f4fb"; }

.bi-plus-square-fill::before {
  content: "\f4fc"; }

.bi-plus-square::before {
  content: "\f4fd"; }

.bi-plus::before {
  content: "\f4fe"; }

.bi-power::before {
  content: "\f4ff"; }

.bi-printer-fill::before {
  content: "\f500"; }

.bi-printer::before {
  content: "\f501"; }

.bi-puzzle-fill::before {
  content: "\f502"; }

.bi-puzzle::before {
  content: "\f503"; }

.bi-question-circle-fill::before {
  content: "\f504"; }

.bi-question-circle::before {
  content: "\f505"; }

.bi-question-diamond-fill::before {
  content: "\f506"; }

.bi-question-diamond::before {
  content: "\f507"; }

.bi-question-octagon-fill::before {
  content: "\f508"; }

.bi-question-octagon::before {
  content: "\f509"; }

.bi-question-square-fill::before {
  content: "\f50a"; }

.bi-question-square::before {
  content: "\f50b"; }

.bi-question::before {
  content: "\f50c"; }

.bi-rainbow::before {
  content: "\f50d"; }

.bi-receipt-cutoff::before {
  content: "\f50e"; }

.bi-receipt::before {
  content: "\f50f"; }

.bi-reception-0::before {
  content: "\f510"; }

.bi-reception-1::before {
  content: "\f511"; }

.bi-reception-2::before {
  content: "\f512"; }

.bi-reception-3::before {
  content: "\f513"; }

.bi-reception-4::before {
  content: "\f514"; }

.bi-record-btn-fill::before {
  content: "\f515"; }

.bi-record-btn::before {
  content: "\f516"; }

.bi-record-circle-fill::before {
  content: "\f517"; }

.bi-record-circle::before {
  content: "\f518"; }

.bi-record-fill::before {
  content: "\f519"; }

.bi-record::before {
  content: "\f51a"; }

.bi-record2-fill::before {
  content: "\f51b"; }

.bi-record2::before {
  content: "\f51c"; }

.bi-reply-all-fill::before {
  content: "\f51d"; }

.bi-reply-all::before {
  content: "\f51e"; }

.bi-reply-fill::before {
  content: "\f51f"; }

.bi-reply::before {
  content: "\f520"; }

.bi-rss-fill::before {
  content: "\f521"; }

.bi-rss::before {
  content: "\f522"; }

.bi-rulers::before {
  content: "\f523"; }

.bi-save-fill::before {
  content: "\f524"; }

.bi-save::before {
  content: "\f525"; }

.bi-save2-fill::before {
  content: "\f526"; }

.bi-save2::before {
  content: "\f527"; }

.bi-scissors::before {
  content: "\f528"; }

.bi-screwdriver::before {
  content: "\f529"; }

.bi-search::before {
  content: "\f52a"; }

.bi-segmented-nav::before {
  content: "\f52b"; }

.bi-server::before {
  content: "\f52c"; }

.bi-share-fill::before {
  content: "\f52d"; }

.bi-share::before {
  content: "\f52e"; }

.bi-shield-check::before {
  content: "\f52f"; }

.bi-shield-exclamation::before {
  content: "\f530"; }

.bi-shield-fill-check::before {
  content: "\f531"; }

.bi-shield-fill-exclamation::before {
  content: "\f532"; }

.bi-shield-fill-minus::before {
  content: "\f533"; }

.bi-shield-fill-plus::before {
  content: "\f534"; }

.bi-shield-fill-x::before {
  content: "\f535"; }

.bi-shield-fill::before {
  content: "\f536"; }

.bi-shield-lock-fill::before {
  content: "\f537"; }

.bi-shield-lock::before {
  content: "\f538"; }

.bi-shield-minus::before {
  content: "\f539"; }

.bi-shield-plus::before {
  content: "\f53a"; }

.bi-shield-shaded::before {
  content: "\f53b"; }

.bi-shield-slash-fill::before {
  content: "\f53c"; }

.bi-shield-slash::before {
  content: "\f53d"; }

.bi-shield-x::before {
  content: "\f53e"; }

.bi-shield::before {
  content: "\f53f"; }

.bi-shift-fill::before {
  content: "\f540"; }

.bi-shift::before {
  content: "\f541"; }

.bi-shop-window::before {
  content: "\f542"; }

.bi-shop::before {
  content: "\f543"; }

.bi-shuffle::before {
  content: "\f544"; }

.bi-signpost-2-fill::before {
  content: "\f545"; }

.bi-signpost-2::before {
  content: "\f546"; }

.bi-signpost-fill::before {
  content: "\f547"; }

.bi-signpost-split-fill::before {
  content: "\f548"; }

.bi-signpost-split::before {
  content: "\f549"; }

.bi-signpost::before {
  content: "\f54a"; }

.bi-sim-fill::before {
  content: "\f54b"; }

.bi-sim::before {
  content: "\f54c"; }

.bi-skip-backward-btn-fill::before {
  content: "\f54d"; }

.bi-skip-backward-btn::before {
  content: "\f54e"; }

.bi-skip-backward-circle-fill::before {
  content: "\f54f"; }

.bi-skip-backward-circle::before {
  content: "\f550"; }

.bi-skip-backward-fill::before {
  content: "\f551"; }

.bi-skip-backward::before {
  content: "\f552"; }

.bi-skip-end-btn-fill::before {
  content: "\f553"; }

.bi-skip-end-btn::before {
  content: "\f554"; }

.bi-skip-end-circle-fill::before {
  content: "\f555"; }

.bi-skip-end-circle::before {
  content: "\f556"; }

.bi-skip-end-fill::before {
  content: "\f557"; }

.bi-skip-end::before {
  content: "\f558"; }

.bi-skip-forward-btn-fill::before {
  content: "\f559"; }

.bi-skip-forward-btn::before {
  content: "\f55a"; }

.bi-skip-forward-circle-fill::before {
  content: "\f55b"; }

.bi-skip-forward-circle::before {
  content: "\f55c"; }

.bi-skip-forward-fill::before {
  content: "\f55d"; }

.bi-skip-forward::before {
  content: "\f55e"; }

.bi-skip-start-btn-fill::before {
  content: "\f55f"; }

.bi-skip-start-btn::before {
  content: "\f560"; }

.bi-skip-start-circle-fill::before {
  content: "\f561"; }

.bi-skip-start-circle::before {
  content: "\f562"; }

.bi-skip-start-fill::before {
  content: "\f563"; }

.bi-skip-start::before {
  content: "\f564"; }

.bi-slack::before {
  content: "\f565"; }

.bi-slash-circle-fill::before {
  content: "\f566"; }

.bi-slash-circle::before {
  content: "\f567"; }

.bi-slash-square-fill::before {
  content: "\f568"; }

.bi-slash-square::before {
  content: "\f569"; }

.bi-slash::before {
  content: "\f56a"; }

.bi-sliders::before {
  content: "\f56b"; }

.bi-smartwatch::before {
  content: "\f56c"; }

.bi-snow::before {
  content: "\f56d"; }

.bi-snow2::before {
  content: "\f56e"; }

.bi-snow3::before {
  content: "\f56f"; }

.bi-sort-alpha-down-alt::before {
  content: "\f570"; }

.bi-sort-alpha-down::before {
  content: "\f571"; }

.bi-sort-alpha-up-alt::before {
  content: "\f572"; }

.bi-sort-alpha-up::before {
  content: "\f573"; }

.bi-sort-down-alt::before {
  content: "\f574"; }

.bi-sort-down::before {
  content: "\f575"; }

.bi-sort-numeric-down-alt::before {
  content: "\f576"; }

.bi-sort-numeric-down::before {
  content: "\f577"; }

.bi-sort-numeric-up-alt::before {
  content: "\f578"; }

.bi-sort-numeric-up::before {
  content: "\f579"; }

.bi-sort-up-alt::before {
  content: "\f57a"; }

.bi-sort-up::before {
  content: "\f57b"; }

.bi-soundwave::before {
  content: "\f57c"; }

.bi-speaker-fill::before {
  content: "\f57d"; }

.bi-speaker::before {
  content: "\f57e"; }

.bi-speedometer::before {
  content: "\f57f"; }

.bi-speedometer2::before {
  content: "\f580"; }

.bi-spellcheck::before {
  content: "\f581"; }

.bi-square-fill::before {
  content: "\f582"; }

.bi-square-half::before {
  content: "\f583"; }

.bi-square::before {
  content: "\f584"; }

.bi-stack::before {
  content: "\f585"; }

.bi-star-fill::before {
  content: "\f586"; }

.bi-star-half::before {
  content: "\f587"; }

.bi-star::before {
  content: "\f588"; }

.bi-stars::before {
  content: "\f589"; }

.bi-stickies-fill::before {
  content: "\f58a"; }

.bi-stickies::before {
  content: "\f58b"; }

.bi-sticky-fill::before {
  content: "\f58c"; }

.bi-sticky::before {
  content: "\f58d"; }

.bi-stop-btn-fill::before {
  content: "\f58e"; }

.bi-stop-btn::before {
  content: "\f58f"; }

.bi-stop-circle-fill::before {
  content: "\f590"; }

.bi-stop-circle::before {
  content: "\f591"; }

.bi-stop-fill::before {
  content: "\f592"; }

.bi-stop::before {
  content: "\f593"; }

.bi-stoplights-fill::before {
  content: "\f594"; }

.bi-stoplights::before {
  content: "\f595"; }

.bi-stopwatch-fill::before {
  content: "\f596"; }

.bi-stopwatch::before {
  content: "\f597"; }

.bi-subtract::before {
  content: "\f598"; }

.bi-suit-club-fill::before {
  content: "\f599"; }

.bi-suit-club::before {
  content: "\f59a"; }

.bi-suit-diamond-fill::before {
  content: "\f59b"; }

.bi-suit-diamond::before {
  content: "\f59c"; }

.bi-suit-heart-fill::before {
  content: "\f59d"; }

.bi-suit-heart::before {
  content: "\f59e"; }

.bi-suit-spade-fill::before {
  content: "\f59f"; }

.bi-suit-spade::before {
  content: "\f5a0"; }

.bi-sun-fill::before {
  content: "\f5a1"; }

.bi-sun::before {
  content: "\f5a2"; }

.bi-sunglasses::before {
  content: "\f5a3"; }

.bi-sunrise-fill::before {
  content: "\f5a4"; }

.bi-sunrise::before {
  content: "\f5a5"; }

.bi-sunset-fill::before {
  content: "\f5a6"; }

.bi-sunset::before {
  content: "\f5a7"; }

.bi-symmetry-horizontal::before {
  content: "\f5a8"; }

.bi-symmetry-vertical::before {
  content: "\f5a9"; }

.bi-table::before {
  content: "\f5aa"; }

.bi-tablet-fill::before {
  content: "\f5ab"; }

.bi-tablet-landscape-fill::before {
  content: "\f5ac"; }

.bi-tablet-landscape::before {
  content: "\f5ad"; }

.bi-tablet::before {
  content: "\f5ae"; }

.bi-tag-fill::before {
  content: "\f5af"; }

.bi-tag::before {
  content: "\f5b0"; }

.bi-tags-fill::before {
  content: "\f5b1"; }

.bi-tags::before {
  content: "\f5b2"; }

.bi-telegram::before {
  content: "\f5b3"; }

.bi-telephone-fill::before {
  content: "\f5b4"; }

.bi-telephone-forward-fill::before {
  content: "\f5b5"; }

.bi-telephone-forward::before {
  content: "\f5b6"; }

.bi-telephone-inbound-fill::before {
  content: "\f5b7"; }

.bi-telephone-inbound::before {
  content: "\f5b8"; }

.bi-telephone-minus-fill::before {
  content: "\f5b9"; }

.bi-telephone-minus::before {
  content: "\f5ba"; }

.bi-telephone-outbound-fill::before {
  content: "\f5bb"; }

.bi-telephone-outbound::before {
  content: "\f5bc"; }

.bi-telephone-plus-fill::before {
  content: "\f5bd"; }

.bi-telephone-plus::before {
  content: "\f5be"; }

.bi-telephone-x-fill::before {
  content: "\f5bf"; }

.bi-telephone-x::before {
  content: "\f5c0"; }

.bi-telephone::before {
  content: "\f5c1"; }

.bi-terminal-fill::before {
  content: "\f5c2"; }

.bi-terminal::before {
  content: "\f5c3"; }

.bi-text-center::before {
  content: "\f5c4"; }

.bi-text-indent-left::before {
  content: "\f5c5"; }

.bi-text-indent-right::before {
  content: "\f5c6"; }

.bi-text-left::before {
  content: "\f5c7"; }

.bi-text-paragraph::before {
  content: "\f5c8"; }

.bi-text-right::before {
  content: "\f5c9"; }

.bi-textarea-resize::before {
  content: "\f5ca"; }

.bi-textarea-t::before {
  content: "\f5cb"; }

.bi-textarea::before {
  content: "\f5cc"; }

.bi-thermometer-half::before {
  content: "\f5cd"; }

.bi-thermometer-high::before {
  content: "\f5ce"; }

.bi-thermometer-low::before {
  content: "\f5cf"; }

.bi-thermometer-snow::before {
  content: "\f5d0"; }

.bi-thermometer-sun::before {
  content: "\f5d1"; }

.bi-thermometer::before {
  content: "\f5d2"; }

.bi-three-dots-vertical::before {
  content: "\f5d3"; }

.bi-three-dots::before {
  content: "\f5d4"; }

.bi-toggle-off::before {
  content: "\f5d5"; }

.bi-toggle-on::before {
  content: "\f5d6"; }

.bi-toggle2-off::before {
  content: "\f5d7"; }

.bi-toggle2-on::before {
  content: "\f5d8"; }

.bi-toggles::before {
  content: "\f5d9"; }

.bi-toggles2::before {
  content: "\f5da"; }

.bi-tools::before {
  content: "\f5db"; }

.bi-tornado::before {
  content: "\f5dc"; }

.bi-trash-fill::before {
  content: "\f5dd"; }

.bi-trash::before {
  content: "\f5de"; }

.bi-trash2-fill::before {
  content: "\f5df"; }

.bi-trash2::before {
  content: "\f5e0"; }

.bi-tree-fill::before {
  content: "\f5e1"; }

.bi-tree::before {
  content: "\f5e2"; }

.bi-triangle-fill::before {
  content: "\f5e3"; }

.bi-triangle-half::before {
  content: "\f5e4"; }

.bi-triangle::before {
  content: "\f5e5"; }

.bi-trophy-fill::before {
  content: "\f5e6"; }

.bi-trophy::before {
  content: "\f5e7"; }

.bi-tropical-storm::before {
  content: "\f5e8"; }

.bi-truck-flatbed::before {
  content: "\f5e9"; }

.bi-truck::before {
  content: "\f5ea"; }

.bi-tsunami::before {
  content: "\f5eb"; }

.bi-tv-fill::before {
  content: "\f5ec"; }

.bi-tv::before {
  content: "\f5ed"; }

.bi-twitch::before {
  content: "\f5ee"; }

.bi-twitter::before {
  content: "\f5ef"; }

.bi-type-bold::before {
  content: "\f5f0"; }

.bi-type-h1::before {
  content: "\f5f1"; }

.bi-type-h2::before {
  content: "\f5f2"; }

.bi-type-h3::before {
  content: "\f5f3"; }

.bi-type-italic::before {
  content: "\f5f4"; }

.bi-type-strikethrough::before {
  content: "\f5f5"; }

.bi-type-underline::before {
  content: "\f5f6"; }

.bi-type::before {
  content: "\f5f7"; }

.bi-ui-checks-grid::before {
  content: "\f5f8"; }

.bi-ui-checks::before {
  content: "\f5f9"; }

.bi-ui-radios-grid::before {
  content: "\f5fa"; }

.bi-ui-radios::before {
  content: "\f5fb"; }

.bi-umbrella-fill::before {
  content: "\f5fc"; }

.bi-umbrella::before {
  content: "\f5fd"; }

.bi-union::before {
  content: "\f5fe"; }

.bi-unlock-fill::before {
  content: "\f5ff"; }

.bi-unlock::before {
  content: "\f600"; }

.bi-upc-scan::before {
  content: "\f601"; }

.bi-upc::before {
  content: "\f602"; }

.bi-upload::before {
  content: "\f603"; }

.bi-vector-pen::before {
  content: "\f604"; }

.bi-view-list::before {
  content: "\f605"; }

.bi-view-stacked::before {
  content: "\f606"; }

.bi-vinyl-fill::before {
  content: "\f607"; }

.bi-vinyl::before {
  content: "\f608"; }

.bi-voicemail::before {
  content: "\f609"; }

.bi-volume-down-fill::before {
  content: "\f60a"; }

.bi-volume-down::before {
  content: "\f60b"; }

.bi-volume-mute-fill::before {
  content: "\f60c"; }

.bi-volume-mute::before {
  content: "\f60d"; }

.bi-volume-off-fill::before {
  content: "\f60e"; }

.bi-volume-off::before {
  content: "\f60f"; }

.bi-volume-up-fill::before {
  content: "\f610"; }

.bi-volume-up::before {
  content: "\f611"; }

.bi-vr::before {
  content: "\f612"; }

.bi-wallet-fill::before {
  content: "\f613"; }

.bi-wallet::before {
  content: "\f614"; }

.bi-wallet2::before {
  content: "\f615"; }

.bi-watch::before {
  content: "\f616"; }

.bi-water::before {
  content: "\f617"; }

.bi-whatsapp::before {
  content: "\f618"; }

.bi-wifi-1::before {
  content: "\f619"; }

.bi-wifi-2::before {
  content: "\f61a"; }

.bi-wifi-off::before {
  content: "\f61b"; }

.bi-wifi::before {
  content: "\f61c"; }

.bi-wind::before {
  content: "\f61d"; }

.bi-window-dock::before {
  content: "\f61e"; }

.bi-window-sidebar::before {
  content: "\f61f"; }

.bi-window::before {
  content: "\f620"; }

.bi-wrench::before {
  content: "\f621"; }

.bi-x-circle-fill::before {
  content: "\f622"; }

.bi-x-circle::before {
  content: "\f623"; }

.bi-x-diamond-fill::before {
  content: "\f624"; }

.bi-x-diamond::before {
  content: "\f625"; }

.bi-x-octagon-fill::before {
  content: "\f626"; }

.bi-x-octagon::before {
  content: "\f627"; }

.bi-x-square-fill::before {
  content: "\f628"; }

.bi-x-square::before {
  content: "\f629"; }

.bi-x::before {
  content: "\f62a"; }

.bi-youtube::before {
  content: "\f62b"; }

.bi-zoom-in::before {
  content: "\f62c"; }

.bi-zoom-out::before {
  content: "\f62d"; }

.bi-bank::before {
  content: "\f62e"; }

.bi-bank2::before {
  content: "\f62f"; }

.bi-bell-slash-fill::before {
  content: "\f630"; }

.bi-bell-slash::before {
  content: "\f631"; }

.bi-cash-coin::before {
  content: "\f632"; }

.bi-check-lg::before {
  content: "\f633"; }

.bi-coin::before {
  content: "\f634"; }

.bi-currency-bitcoin::before {
  content: "\f635"; }

.bi-currency-dollar::before {
  content: "\f636"; }

.bi-currency-euro::before {
  content: "\f637"; }

.bi-currency-exchange::before {
  content: "\f638"; }

.bi-currency-pound::before {
  content: "\f639"; }

.bi-currency-yen::before {
  content: "\f63a"; }

.bi-dash-lg::before {
  content: "\f63b"; }

.bi-exclamation-lg::before {
  content: "\f63c"; }

.bi-file-earmark-pdf-fill::before {
  content: "\f63d"; }

.bi-file-earmark-pdf::before {
  content: "\f63e"; }

.bi-file-pdf-fill::before {
  content: "\f63f"; }

.bi-file-pdf::before {
  content: "\f640"; }

.bi-gender-ambiguous::before {
  content: "\f641"; }

.bi-gender-female::before {
  content: "\f642"; }

.bi-gender-male::before {
  content: "\f643"; }

.bi-gender-trans::before {
  content: "\f644"; }

.bi-headset-vr::before {
  content: "\f645"; }

.bi-info-lg::before {
  content: "\f646"; }

.bi-mastodon::before {
  content: "\f647"; }

.bi-messenger::before {
  content: "\f648"; }

.bi-piggy-bank-fill::before {
  content: "\f649"; }

.bi-piggy-bank::before {
  content: "\f64a"; }

.bi-pin-map-fill::before {
  content: "\f64b"; }

.bi-pin-map::before {
  content: "\f64c"; }

.bi-plus-lg::before {
  content: "\f64d"; }

.bi-question-lg::before {
  content: "\f64e"; }

.bi-recycle::before {
  content: "\f64f"; }

.bi-reddit::before {
  content: "\f650"; }

.bi-safe-fill::before {
  content: "\f651"; }

.bi-safe2-fill::before {
  content: "\f652"; }

.bi-safe2::before {
  content: "\f653"; }

.bi-sd-card-fill::before {
  content: "\f654"; }

.bi-sd-card::before {
  content: "\f655"; }

.bi-skype::before {
  content: "\f656"; }

.bi-slash-lg::before {
  content: "\f657"; }

.bi-translate::before {
  content: "\f658"; }

.bi-x-lg::before {
  content: "\f659"; }

.bi-safe::before {
  content: "\f65a"; }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v20-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/roboto-v20-latin-500.woff2") format("woff2"), url("../fonts/roboto-v20-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v20-latin-700.woff2") format("woff2"), url("../fonts/roboto-v20-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

.btn-success {
  background-color: #388e3c;
  border-color: #388e3c; }

html {
  height: 100%;
  scroll-behavior: smooth; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.appBody {
  flex-shrink: 0; }

.appContainer {
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .appContainer {
      margin-top: 65px;
      margin-bottom: 65px; } }

.appColumns {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .appColumns {
      margin-top: 65px;
      margin-bottom: 65px; } }

.appSidebar {
  flex: 0 0 300px;
  max-width: 300px;
  margin-right: 90px; }
  @media (max-width: 991.98px) {
    .appSidebar {
      display: none; } }

.subMenu {
  padding-left: 0;
  list-style: none; }
  .subMenu ul {
    list-style: none;
    padding-left: 30px; }
  .subMenu li {
    margin-bottom: 4px; }
  .subMenu a {
    color: #000;
    font-size: 18px; }
  .subMenu .selected > a {
    font-weight: 700; }
  .subMenu .child > ul {
    display: none; }
  .subMenu .child.ancestor > ul, .subMenu .child.selected > ul {
    display: inherit; }

.appSection {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .appSection {
      padding: 90px 0; } }
  .appSection__title {
    color: #162c69;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px; }

@media (max-width: 575.98px) {
  .container {
    padding-right: 30px;
    padding-left: 30px; } }

.addItem img {
  margin-left: 4px !important;
  vertical-align: -3px; }

.backToTop {
  border-width: 2px;
  border-radius: 6px;
  font-size: 2em;
  line-height: 1;
  opacity: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1070; }
  .backToTop.active {
    opacity: 1;
    visibility: visible; }
  .backToTop svg {
    transform: rotate(180deg);
    vertical-align: top; }

table {
  border-color: #eeeeee;
  border: 1px solid #eeeeee; }
  table th {
    font-weight: bold;
    background-color: #eeeeee; }
  table th, table td {
    padding: 10px;
    vertical-align: top;
    border: 1px solid #cccccc; }
    table th p, table td p {
      margin-bottom: 0; }

.appHeader {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: -64px;
  z-index: 1020; }
  @media (max-width: 991.98px) {
    .appHeader {
      top: 0; } }
  .cms-toolbar-expanded .appHeader {
    top: -18px; }
    @media (max-width: 991.98px) {
      .cms-toolbar-expanded .appHeader {
        top: 46px; } }
  .appHeader__mobile {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 20px;
    position: relative;
    z-index: 1; }
    @media (min-width: 992px) {
      .appHeader__mobile {
        display: none; } }
  @media (max-width: 991.98px) {
    .appHeader__sidebar {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      overflow-y: auto;
      position: fixed;
      top: 73px;
      bottom: 0;
      left: -320px;
      transition: left 0.15s;
      width: 320px; }
      .cms-toolbar-expanded .appHeader__sidebar {
        top: 119px; }
      .appHeader__sidebar.is-active {
        left: 0; } }
  .appHeader__overlay {
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .15s; }
    .appHeader__overlay.is-active {
      opacity: 0.75;
      visibility: visible; }
    @media (min-width: 992px) {
      .appHeader__overlay {
        display: none; } }
  .appHeader__top {
    background-color: #0b1737;
    padding: 12px 20px 16px; }
    @media (min-width: 992px) {
      .appHeader__top {
        padding: 20px 40px 16px; } }
    @media (min-width: 1200px) {
      .appHeader__top {
        padding-right: calc((100% - 1328px) / 2);
        padding-left: calc((100% - 1110px) / 2); } }
  .appHeader__topContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end; }
    @media (min-width: 1200px) {
      .appHeader__topContainer {
        padding-right: 20px; } }
  .appHeader__login {
    margin-bottom: 16px;
    margin-left: auto;
    min-width: 190px; }
    @media (min-width: 992px) {
      .appHeader__login {
        margin: 0 15px; } }
  .appHeader__main {
    position: relative; }
  @media (min-width: 992px) {
    .appHeader__mainContainer {
      display: flex;
      align-items: center;
      padding: 0 20px; } }
  @media (min-width: 1200px) {
    .appHeader__mainContainer {
      padding-right: calc((100% - 1328px) / 2);
      padding-left: calc((100% - 1110px) / 2); } }

.languageChooser {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin: 0 0 10px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .languageChooser {
      margin: 0; } }
  .languageChooser li:not(:last-child)::after {
    content: "-"; }
  .languageChooser a {
    color: #fff;
    padding: 0 4px; }

.headerLinks {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin: 0 0 10px; }
  @media (min-width: 992px) {
    .headerLinks {
      margin: 0 10px; } }
  @media (min-width: 992px) {
    .headerLinks__item {
      margin: 0 10px; } }
  .headerLinks__link {
    color: #fff;
    padding: 0 5px; }
    .headerLinks__link:hover {
      color: #fff; }

.appLogo {
  margin-right: 20px; }
  @media (max-width: 991.98px) {
    .appLogo--lg {
      display: none; } }
  @media (max-width: 1199.98px) {
    .appLogo__img {
      width: 165px;
      height: 32px; } }
  @media (min-width: 992px) {
    .appLogo {
      padding: 20px 0;
      width: 217px; }
      .appLogo__img {
        width: 100%;
        height: auto; } }

@media (min-width: 992px) {
  .appNav {
    margin-left: auto; } }

.appMenu {
  padding-left: 0;
  list-style: none;
  margin: 0; }
  @media (max-width: 991.98px) {
    .appMenu {
      padding: 20px; } }
  @media (min-width: 992px) {
    .appMenu {
      display: flex; } }
  .appMenu__topItem {
    font-size: 20px; }
    @media (max-width: 991.98px) {
      .appMenu__topItem {
        margin-bottom: 1.5em; } }
    @media (min-width: 992px) {
      .appMenu__topItem {
        font-size: 18px; } }
    @media (min-width: 1200px) {
      .appMenu__topItem {
        font-size: 20px; } }
  .appMenu__topLink {
    color: #353535;
    display: block; }
    .appMenu__topLink:focus {
      box-shadow: 0 0 0 0.2rem #95abe9;
      outline: none; }
    .appMenu__topLink:hover {
      text-decoration: none; }
    @media (max-width: 991.98px) {
      .appMenu__topLink {
        font-weight: 700; } }
    @media (min-width: 992px) {
      .appMenu__topLink {
        padding: 39px 20px;
        transition: all .15s;
        white-space: nowrap; } }
  @media (min-width: 992px) {
    .appMenu__topItem:hover .appMenu__topLink {
      background-color: #162C69;
      color: #fff; } }
  @media (min-width: 992px) {
    .appMenu__subMenuContainer {
      background-color: #162C69;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear 0.3s; }
      li:hover > .appMenu__subMenuContainer {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.15s; } }
  .appMenu__subMenu {
    padding-left: 0;
    list-style: none;
    margin: 0; }
    @media (min-width: 992px) {
      .appMenu__subMenu {
        column-count: 3;
        column-gap: 15px;
        padding: 40px;
        max-width: 1000px;
        margin: auto; } }
    .appMenu__subMenu ul {
      padding-left: 0;
      list-style: none;
      margin: 0; }
    .appMenu__subMenu a {
      color: #353535;
      display: block; }
      @media (min-width: 992px) {
        .appMenu__subMenu a {
          color: #fff; } }
      .appMenu__subMenu a:focus {
        outline: none;
        text-decoration: underline; }
    .appMenu__subMenu > li {
      display: inline-block;
      font-size: 18px;
      margin-bottom: 1.5em;
      width: 100%; }
      .appMenu__subMenu > li > a {
        font-weight: 700; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #162C69; }

.hamburger-box {
  width: 44px;
  height: 23px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 44px;
    height: 3px;
    background-color: #162C69;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-6.28571px, -7px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

.hamburger {
  margin-left: auto; }
  .hamburger:focus {
    outline: none; }

.hamburger-box {
  display: block; }

.appSearch {
  display: flex;
  position: relative;
  width: 100%; }
  @media (min-width: 992px) {
    .appSearch {
      margin-left: 15px;
      width: 190px; } }
  .appSearch__control {
    background-color: transparent;
    height: 28px;
    line-height: 18px;
    padding-right: 42px;
    padding-left: 12px;
    color: white; }
  .appSearch__btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 40px;
    height: 28px; }
    .appSearch__btn:hover, .appSearch__btn:focus {
      background-color: #fff;
      color: #162C69; }
    .appSearch__btn svg {
      vertical-align: -2px; }
  .appSearch__control:focus + .appSearch__btn {
    color: #162C69; }

.searchForm {
  margin-bottom: 20px;
  position: relative;
  width: 50%; }
  .searchForm__btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    top: 1px;
    right: 0;
    padding: 0;
    width: 50px;
    height: 48px;
    color: #162C69; }
    .searchForm__btn:focus {
      box-shadow: none;
      background-color: #162C69;
      color: #ffffff; }

.searchResults {
  padding-bottom: 2em; }
  .searchResults__list {
    padding-top: 1em; }
  .searchResults__item {
    margin-bottom: 2em; }
    .searchResults__item__description {
      margin-bottom: 10px; }
      .searchResults__item__description em {
        background-color: yellow;
        font-style: normal; }
  .searchResults__pagination a {
    padding-right: 1em; }

.appFooter {
  background-color: #242b31;
  color: #fff;
  margin-top: auto; }
  .appFooter__top {
    background-color: #0b1737;
    padding-top: 60px;
    padding-bottom: 60px; }
  .appFooter__middle {
    background-color: #0f235a;
    font-size: 20px;
    padding-top: 45px;
    padding-bottom: 60px; }
    .appFooter__middle h2 {
      font-size: 22px;
      margin-bottom: 0; }
  .appFooter__bottom {
    padding-top: 30px;
    padding-bottom: 15px; }
    .appFooter__bottom .container {
      display: flex; }
      .appFooter__bottom .container .socialIcons {
        margin-left: auto; }
        .appFooter__bottom .container .socialIcons .linkedin__link img {
          height: 40px;
          margin-top: 2px; }
  .appFooter a {
    color: #fff; }

.footerMenu {
  padding-left: 0;
  list-style: none;
  margin: -15px; }
  @media (min-width: 768px) {
    .footerMenu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .footerMenu > li {
    margin: 0 15px 3em; }
    @media (min-width: 768px) {
      .footerMenu > li {
        margin-bottom: 0; } }
    .footerMenu > li > a {
      font-size: 18px;
      font-weight: 700; }
    .footerMenu > li > ul {
      margin-top: 1.5em; }
  .footerMenu ul {
    padding-left: 0;
    list-style: none;
    margin: 0; }

.breadcrumb {
  margin-top: 30px; }
  @media (min-width: 992px) {
    .breadcrumb {
      margin-top: 65px; } }
  .breadcrumb-item.active {
    font-weight: 700; }
  .breadcrumb-item.home {
    padding-right: 4px; }
    .breadcrumb-item.home svg {
      vertical-align: baseline; }
  .breadcrumb-item a {
    color: #000; }

.appContent {
  font-size: 17px;
  line-height: 1.37; }
  .appContent a {
    text-decoration: underline; }

h1 {
  color: #ae1a22;
  margin-bottom: 0.3em; }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 2.25rem; } }

h2 {
  font-size: 26px; }

h3 {
  font-size: 22px; }

h5 {
  font-size: 18px; }

p,
ul,
ol,
figure {
  margin-bottom: 1.37em; }

.blockquote, blockquote {
  border-color: #ccc;
  border-style: solid;
  border-width: 0 0 0 5px;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-style: italic;
  font-size: 17px;
  margin: 16px 40px;
  padding: 2px 8px 2px 20px; }

.introSection {
  background-color: #5c83b5;
  background-image: url(../images/intro2.jpg);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #0D1837;
  margin-top: -175px;
  padding-top: 230px;
  padding-bottom: 60px; }
  @media (min-width: 992px) {
    .introSection {
      padding-top: 260px;
      padding-bottom: 110px; } }
  @media (min-width: 1200px) {
    .introSection {
      background-position: 50% 100%; } }
  @media (min-width: 992px) {
    .introSection__content {
      max-width: 700px;
      margin-left: auto; } }
  .introSection__title {
    color: inherit;
    font-weight: 400; }
    @media (min-width: 992px) {
      .introSection__title {
        font-size: 60px; } }
  .introSection__description {
    font-size: 27px; }
  .introSection__features {
    margin-top: 70px; }
  .introSection__footer {
    margin-top: 20px; }
  .introSection__btn {
    background-color: #ffc009;
    color: #0d1837;
    border-color: #ffc009; }

@media (min-width: 576px) {
  .introSectionFeatures {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  .introSectionFeatures {
    flex-wrap: nowrap; } }

.introSectionFeatures__item {
  margin-bottom: 30px; }
  @media (min-width: 576px) {
    .introSectionFeatures__item {
      width: 50%; } }
  @media (min-width: 768px) {
    .introSectionFeatures__item {
      width: auto; } }

.introSectionFeatures__icon {
  display: flex;
  align-items: center;
  height: 94px;
  margin-bottom: 25px; }
  @media (min-width: 576px) {
    .introSectionFeatures__icon {
      border-right: 2px solid #0D1837;
      padding: 0 40px; } }
  .introSectionFeatures__icon img {
    margin: auto; }

.introSectionFeatures__text {
  font-size: 16px;
  text-align: center;
  line-height: 1.3; }
  @media (min-width: 576px) {
    .introSectionFeatures__text {
      padding: 0 35px; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .introSectionFeatures__item:nth-child(odd) .introSectionFeatures__icon,
  .introSectionFeatures__item:nth-child(odd) .introSectionFeatures__text {
    padding-left: 0; }
  .introSectionFeatures__item:nth-child(even) .introSectionFeatures__icon,
  .introSectionFeatures__item:nth-child(even) .introSectionFeatures__text {
    border-right: 0;
    padding-right: 0; } }

@media (min-width: 768px) {
  .introSectionFeatures__item:first-child .introSectionFeatures__icon,
  .introSectionFeatures__item:first-child .introSectionFeatures__text {
    padding-left: 0; }
  .introSectionFeatures__item:last-child .introSectionFeatures__icon,
  .introSectionFeatures__item:last-child .introSectionFeatures__text {
    border-right: 0;
    padding-right: 0; } }

.latestNews {
  background-color: #f8f8f8; }
  @media (min-width: 576px) {
    .latestNews__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px; } }
  .latestNews__item {
    margin-bottom: 40px; }
    @media (min-width: 576px) {
      .latestNews__item {
        padding-right: 20px;
        padding-left: 20px;
        width: 50%; } }
    @media (min-width: 1200px) {
      .latestNews__item {
        width: 25%; } }
  .latestNews__footer {
    text-align: right; }

.latestNewsItem {
  background: #fafafa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative; }
  .latestNewsItem__media {
    overflow: hidden;
    position: relative; }
    .latestNewsItem__media::before {
      content: '';
      display: block;
      padding-top: 66.67%; }
  .latestNewsItem__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; }
  .latestNewsItem__body {
    color: #505050;
    padding: 20px; }
  .latestNewsItem__title {
    font-size: 16px;
    margin-bottom: 1.2em; }
    .latestNewsItem__title a {
      color: inherit; }
  .latestNewsItem__description {
    font-size: 14px; }
  .latestNewsItem__date {
    font-weight: bold; }
  .latestNewsItem__footer {
    background-color: #f0f0f0;
    color: #9d2423;
    font-size: 15px;
    margin-top: auto;
    padding: 4px 20px;
    text-align: right; }

.newsDetail__meta, .newsDetail__figure, .newsDetail__description {
  margin-bottom: 1.37em; }

.newsListItem {
  border-bottom: 1px solid #ececec;
  padding: 60px 0; }
  @media (min-width: 768px) {
    .newsListItem {
      display: flex; } }
  .newsListItem:first-child {
    padding-top: 0; }
  .newsListItem:last-child {
    border: 0; }
  .newsListItem__figure {
    border: 1px solid #dadada; }
    @media (min-width: 768px) {
      .newsListItem__figure {
        flex-shrink: 0;
        margin-right: 60px;
        width: 182px;
        height: 182px; } }
    .newsListItem__figure img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .newsListItem__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0.5em; }
  .newsListItem__link {
    color: #000; }
  .newsListItem__description {
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 18px; }

.eventDetail {
  margin-top: 0; }
  .eventDetail__meta, .eventDetail__figure, .eventDetail__description {
    margin-bottom: 1.37em; }
  .eventDetail .alert {
    border-radius: 5px;
    margin-bottom: 25px; }
    .eventDetail .alert > div {
      float: left; }
      .eventDetail .alert > div.icon {
        margin-right: 20px; }

.eventDetailMeta > div {
  padding: 5px 0; }

.eventListItem {
  border-bottom: 1px solid #ececec;
  padding: 40px 0; }
  @media (min-width: 768px) {
    .eventListItem {
      display: flex; } }
  .eventListItem:first-child {
    padding-top: 0; }
  .eventListItem:last-child {
    border: 0; }
  .eventListItem__figure {
    border: 1px solid #dadada; }
    @media (min-width: 768px) {
      .eventListItem__figure {
        flex-shrink: 0;
        margin-right: 60px;
        width: 182px;
        height: 182px; } }
    .eventListItem__figure img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .eventListItem__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0.5em; }
  .eventListItem__link {
    color: #000; }
  .eventListItem__description {
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 18px;
    margin-top: 10px; }

.eventListMeta > div {
  padding: 2px 0; }

#event-registration-form .asteriskField {
  color: #ae1a22;
  padding-left: 2px; }

#event-registration-form .form-control, #event-registration-form .custom-select {
  border-radius: 12px; }

#event-registration-form .custom-radio {
  line-height: 35px; }
  #event-registration-form .custom-radio input[type="radio"] {
    margin-top: 10px; }
  #event-registration-form .custom-radio label {
    margin-right: 10px; }
    #event-registration-form .custom-radio label::before, #event-registration-form .custom-radio label::after {
      top: 8px; }

#event-registration-form .form-control-inline {
  display: inline-block;
  width: 50%; }

#event-registration-form #id_captcha_0 {
  display: inline-block;
  width: 50%; }

#event-registration-form .fields-group {
  background-color: #f6f6f6;
  padding: 20px;
  margin-top: 40px; }
  #event-registration-form .fields-group:last-child {
    margin-bottom: 40px; }

#event-registration-form #name-fields .form-group, #event-registration-form #address-fields .form-group {
  width: 50%; }

#event-registration-form #name-fields #div_id_salutation, #event-registration-form #address-fields #div_id_salutation {
  width: 25%; }

#event-registration-form #submit-id-register {
  margin-top: 40px; }

.helpSection {
  background-color: #f8f8f8; }

.productListItem {
  border-bottom: 1px solid #ececec;
  padding: 60px 0; }
  @media (min-width: 768px) {
    .productListItem {
      display: flex; } }
  .productListItem:first-child {
    padding-top: 0; }
  .productListItem:last-child {
    border: 0; }
  .productListItem__figure {
    border: 1px solid #dadada;
    flex-shrink: 0;
    width: 182px;
    height: 182px; }
    @media (min-width: 768px) {
      .productListItem__figure {
        margin-right: 60px; } }
    .productListItem__figure img {
      object-fit: contain;
      width: 100%;
      height: 100%; }
  .productListItem__main {
    flex-grow: 1; }
  @media (min-width: 992px) {
    .productListItem__body {
      display: flex; } }
  .productListItem__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0.5em; }
  .productListItem__link {
    color: #000; }
  .productListItem__description {
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 18px; }
    @media (min-width: 992px) {
      .productListItem__description {
        margin-right: 60px; } }

.productListItemOrder,
.productListItemFile {
  background-color: #4b5157;
  color: #fff;
  display: block;
  font-size: 18px;
  margin-bottom: 18px;
  padding: 12px;
  text-align: center;
  width: 230px; }
  .productListItemOrder__label,
  .productListItemFile__label {
    font-weight: 700; }

.productListItemFile {
  background-color: #f3f3f3;
  color: #000; }

.productDetail__title {
  margin-bottom: 88px;
  color: #123061; }

.productDetail__description {
  margin-bottom: 3em; }

@media (min-width: 992px) {
  .productDetail__aside {
    padding-left: 65px; } }

@media (min-width: 992px) {
  .productDetail__main {
    padding-right: 65px; } }

.productDetail__badge {
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
  padding: 4px 8px;
  border: 2px solid #388e3c;
  color: #388e3c;
  font-weight: 600; }
  .productDetail__badge--onRequest {
    border-color: #61b2be;
    color: #61b2be; }
  .productDetail__badge--retired {
    border-color: #e3b638;
    color: #e3b638; }

.productCardList {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .productCardList {
      margin-right: -20px;
      margin-left: -20px; } }

.productCard {
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  position: relative; }
  @media (min-width: 768px) {
    .productCard {
      margin-right: 20px;
      margin-left: 20px;
      width: calc(50% - 40px); } }
  @media (min-width: 1200px) {
    .productCard {
      width: calc(33.33% - 40px); } }
  .productCard__badge {
    border-radius: 5px;
    display: inline-block;
    font-size: 13px;
    padding: 4px 8px;
    border: 2px solid #388e3c;
    color: #388e3c;
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    right: 10px; }
    .productCard__badge--onRequest {
      border-color: #61b2be;
      color: #61b2be; }
    .productCard__badge--retired {
      border-color: #e3b638;
      color: #e3b638; }
  .productCard__figure {
    margin-bottom: 0;
    position: relative;
    text-align: center;
    background: #fafafa;
    padding-bottom: 10px; }
    .productCard__figure svg {
      max-width: 50%;
      height: auto;
      margin: 25%; }
  .productCard__main {
    padding: 20px;
    font-size: 12px; }
  .productCard__title {
    font-size: 18px;
    padding: 20px;
    background-color: #1b2c65;
    color: white; }
  .productCard__subtitle {
    font-size: 14px;
    text-transform: uppercase; }
  .productCard__info {
    margin-bottom: 2em; }
  .productCard__link {
    color: #ffffff; }
    .productCard__link:hover {
      color: #ffffff;
      text-decoration: underline; }
  .productCard__description, .productCard__order {
    margin-bottom: 6px; }

.productTabs {
  border-color: #162C69;
  margin-bottom: 24px; }
  .productTabs .nav-item {
    margin-bottom: 0;
    margin-right: 2px; }
  .productTabs .nav-link {
    background-color: #e9ecef;
    border: 0;
    border-radius: 3px 3px 0 0;
    color: #162C69;
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 6px;
    transition: all 0.2s ease-in-out; }
    .productTabs .nav-link:hover, .productTabs .nav-link:focus, .productTabs .nav-link.active {
      border-color: #162c69;
      color: #fff;
      background-color: #162C69; }
    .productTabs .nav-link.active {
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 8px; }

.light-gallery-plugin > a {
  position: relative;
  margin: 5px;
  display: inline-block; }
  .light-gallery-plugin > a img {
    opacity: 0.6; }
  .light-gallery-plugin > a:after {
    content: "\f52a";
    font-family: bootstrap-icons;
    color: #000000;
    position: absolute;
    top: 5px;
    right: 10px; }

.productsSection__description {
  font-size: 21px; }

.productsBlock {
  margin-top: 70px;
  text-align: center; }

@media (max-width: 991.98px) {
  .productOverview {
    margin-bottom: 100px; } }

.productOverview__inner {
  display: inline-block;
  padding: 0 45px;
  position: relative; }
  @media (min-width: 768px) {
    .productOverview__inner {
      padding: 0 75px; } }

.productOverviewDetails {
  padding-left: 0;
  list-style: none;
  margin: 0; }
  .productOverviewDetails__link {
    font-size: 12px;
    position: absolute;
    text-align: left;
    text-decoration: underline; }
    @media (max-width: 767.98px) {
      .productOverviewDetails__link {
        white-space: nowrap;
        overflow: hidden; } }
    .productOverviewDetails__link--1 {
      top: 8.8%;
      left: 81%;
      max-width: 19%; }
    .productOverviewDetails__link--2 {
      top: 11.3%;
      right: 74%;
      max-width: 26%; }
    .productOverviewDetails__link--3 {
      top: 14.3%;
      left: 81%;
      max-width: 19%; }
    .productOverviewDetails__link--4 {
      top: 35.3%;
      left: 80%;
      max-width: 20%; }
    .productOverviewDetails__link--5 {
      top: 41.1%;
      right: 77%;
      max-width: 23%; }
    .productOverviewDetails__link--6 {
      top: 46%;
      left: 79%;
      max-width: 21%; }
    .productOverviewDetails__link--7 {
      top: 51.3%;
      left: 81%;
      max-width: 19%; }
    .productOverviewDetails__link--8 {
      top: 55.4%;
      right: 85%;
      max-width: 15%; }
    .productOverviewDetails__link--9 {
      top: 61.7%;
      left: 82%;
      max-width: 18%; }
    .productOverviewDetails__link--10 {
      top: 71.6%;
      left: 89%;
      max-width: 11%; }
    .productOverviewDetails__link--11 {
      top: 74.2%;
      right: 77%;
      max-width: 23%; }
    .productOverviewDetails__link--12 {
      top: 81%;
      left: 82%;
      max-width: 18%; }
    .productOverviewDetails__link--13 {
      top: 80.8%;
      right: 87%;
      max-width: 13%; }
    .productOverviewDetails__link--14 {
      top: 86.6%;
      right: 86%;
      max-width: 14%; }
    .productOverviewDetails__link--15 {
      top: 10%;
      left: 85%;
      max-width: 15%; }
    .productOverviewDetails__link--16 {
      top: 16%;
      right: 80%;
      max-width: 20%; }
    .productOverviewDetails__link--17 {
      top: 34%;
      right: 79%;
      max-width: 21%; }
    .productOverviewDetails__link--18 {
      top: 34%;
      left: 81%;
      max-width: 19%; }
    .productOverviewDetails__link--19 {
      top: 50%;
      right: 77%;
      max-width: 23%; }
    .productOverviewDetails__link--20 {
      top: 50%;
      left: 79%;
      max-width: 21%; }
    .productOverviewDetails__link--21 {
      bottom: 0;
      right: 81%;
      max-width: 19%; }
    @media (max-width: 767.98px) {
      .productOverviewDetails__link--1 {
        left: 85%; }
      .productOverviewDetails__link--2 {
        right: 77%; }
      .productOverviewDetails__link--3 {
        left: 86%; }
      .productOverviewDetails__link--4 {
        left: 85%; }
      .productOverviewDetails__link--5 {
        right: 81%; }
      .productOverviewDetails__link--6 {
        left: 84%; }
      .productOverviewDetails__link--7 {
        top: 54%;
        left: 86%; }
      .productOverviewDetails__link--8 {
        right: 89%; }
      .productOverviewDetails__link--9 {
        left: 87%; }
      .productOverviewDetails__link--11 {
        right: 80%; }
      .productOverviewDetails__link--12 {
        left: 87%; }
      .productOverviewDetails__link--13 {
        right: 88%; }
      .productOverviewDetails__link--14 {
        right: 88%; }
      .productOverviewDetails__link--21 {
        right: 88.5%; } }

@media (min-width: 992px) {
  .tower_bottom {
    position: absolute;
    bottom: 35px; } }

.regionTitle {
  border-bottom: 1px solid #343a40;
  margin-bottom: 1.2em; }

.partnersListItem__title {
  position: relative;
  z-index: -1; }
  .partnersListItem__title::before {
    content: '';
    display: block;
    margin-top: -128px;
    padding-top: 128px; }
    .cms-toolbar-expanded .partnersListItem__title::before {
      margin-top: -174px;
      padding-top: 174px; }

.summary-page {
  padding-top: 10px; }
  .summary-page .summary-page-content {
    padding-top: 20px; }
    .summary-page .summary-page-content .page {
      padding-bottom: 20px; }
      .summary-page .summary-page-content .page .page-title {
        padding-bottom: 10px; }

.sitemap {
  padding-left: 18px; }
  .sitemap li {
    padding: 2px 0; }

.cookie-bar {
  padding: 15px 30px 15px;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  background: #616265;
  color: #fff;
  box-sizing: border-box;
  z-index: 9999;
  opacity: .9; }
  .cookie-bar .cc-cookie-btn {
    background-image: none;
    margin-bottom: 0;
    border-radius: 3px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ddd;
    background-clip: padding-box;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 15px; }
    .cookie-bar .cc-cookie-btn.cc-cookie-accept {
      background-color: #0bf;
      border: 1px solid #0bf;
      color: white; }

.summary-page .page {
  padding: 20px 0;
  border-bottom: 1px dashed #cccccc; }
  .summary-page .page > div {
    width: 100%; }
  .summary-page .page .page-title a {
    font-size: 25px; }
  .summary-page .page img {
    max-width: 300px;
    float: right; }
  .summary-page .page .page-description {
    color: #666666;
    font-style: italic; }

.knowledge-button-container {
  position: relative; }
  .knowledge-button-container .breadcrumb {
    width: calc(100% - 200px); }
  .knowledge-button-container .product-knowledge-button {
    position: absolute;
    right: 55px;
    top: -1px;
    background: #0288d1;
    color: white;
    font-size: 14px;
    padding: 9px 17px;
    border-radius: 28px; }

@media (max-width: 600px) {
  .tower, .tower-container {
    display: none; } }
