.eventDetail {
  margin-top: 0;

  &__meta,
  &__figure,
  &__description {
    margin-bottom: 1.37em;
  }

  .alert {
    border-radius: 5px;
    margin-bottom: 25px;

    > div {
      float: left;

      &.icon {
        margin-right: 20px;
      }
    }
  }
}

.eventDetailMeta {
  > div {
    padding: 5px 0;
  }
}

.eventListItem {
  border-bottom: 1px solid #ececec;
  padding: 40px 0;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
  }

  &__figure {
    border: 1px solid #dadada;

    @include media-breakpoint-up(md) {
      flex-shrink: 0;
      margin-right: 60px;
      width: 182px;
      height: 182px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  &__link {
    color: #000;
  }

  &__description {
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 18px;
    margin-top: 10px;
  }
}

.eventListMeta {

  > div {
    padding: 2px 0;
  }

  &__date {

  }
}

#event-registration-form {
  .asteriskField {
    color: #ae1a22;
    padding-left: 2px;
  }

  .form-control, .custom-select {
    border-radius: 12px;
  }

  .custom-radio {
    line-height: 35px;

    input[type="radio"] {
      margin-top: 10px;
    }

    label {
      margin-right: 10px;
      &::before, &::after {
        top: 8px;
      }
    }
  }

  .form-control-inline {
    display: inline-block;
    width: 50%;
  }

  #id_captcha_0 {
    display: inline-block;
    width: 50%;
  }

  .fields-group {
    background-color: #f6f6f6;
    padding: 20px;
    margin-top: 40px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  #name-fields, #address-fields {
    .form-group {
      width: 50%;
    }
    #div_id_salutation {
      width: 25%;
    }
  }

  #submit-id-register {
    margin-top: 40px;
  }
}
