.latestNews {
  background-color: #f8f8f8;

  &__list {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
  }

  &__item {
    margin-bottom: 40px;

    @include media-breakpoint-up(sm) {
      padding-right: 20px;
      padding-left: 20px;
      width: 50%;
    }

    @include media-breakpoint-up(xl) {
      width: 25%;
    }
  }

  &__footer {
    text-align: right;
  }
}

.latestNewsItem {
  background: #fafafa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__media {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 66.67%;
    }
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  &__body {
    color: #505050;
    padding: 20px;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 1.2em;

    a {
      color: inherit;
    }
  }

  &__description {
    font-size: 14px;
  }

  &__date {
    font-weight: bold;
  }

  &__footer {
    background-color: #f0f0f0;
    color: #9d2423;
    font-size: 15px;
    margin-top: auto;
    padding: 4px 20px;
    text-align: right;
  }
}

.newsDetail {
  &__meta,
  &__figure,
  &__description {
    margin-bottom: 1.37em;
  }
}

.newsListItem {
  border-bottom: 1px solid #ececec;
  padding: 60px 0;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
  }

  &__figure {
    border: 1px solid #dadada;

    @include media-breakpoint-up(md) {
      flex-shrink: 0;
      margin-right: 60px;
      width: 182px;
      height: 182px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  &__link {
    color: #000;
  }

  &__description {
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 18px;
  }
}
