.regionTitle {
  border-bottom: 1px solid $gray-800;
  margin-bottom: 1.2em;
}

.partnersListItem {
  &__title {
    position: relative;
    z-index: -1;

    &::before {
      content: '';
      display: block;
      margin-top: -128px;
      padding-top: 128px;

      .cms-toolbar-expanded & {
        margin-top: -174px;
        padding-top: 174px;
      }
    }
  }

  p {

  }
}
