table {
  border-color: #eeeeee;
  border: 1px solid #eeeeee;

  th {
    font-weight: bold;
    background-color: #eeeeee;
  }

  th, td {
    padding: 10px;
    vertical-align: top;
    border: 1px solid #cccccc;

    p {
      margin-bottom: 0;
    }
  }
}
