@mixin badge {
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
  padding: 4px 8px;
  border: 2px solid #388e3c;
  color: #388e3c;
  font-weight: 600;
}

.productListItem {
  border-bottom: 1px solid #ececec;
  padding: 60px 0;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
  }

  &__figure {
    border: 1px solid #dadada;
    flex-shrink: 0;
    width: 182px;
    height: 182px;

    @include media-breakpoint-up(md) {
      margin-right: 60px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    flex-grow: 1;
  }

  &__body {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  &__link {
    color: #000;
  }

  &__description {
    flex-grow: 1;
    font-size: 22px;
    margin-bottom: 18px;

    @include media-breakpoint-up(lg) {
      margin-right: 60px;
    }
  }
}

.productListItemOrder,
.productListItemFile {
  background-color: #4b5157;
  color: #fff;
  display: block;
  font-size: 18px;
  margin-bottom: 18px;
  padding: 12px;
  text-align: center;
  width: 230px;

  &__label {
    font-weight: 700;
  }
}

.productListItemFile {
  background-color: #f3f3f3;
  color: #000;
}

.productDetail {
  &__title {
    margin-bottom: 88px;
    color: #123061;
  }

  &__description {
    margin-bottom: 3em;
  }

  &__aside {
    @include media-breakpoint-up(lg) {
      padding-left: 65px;
    }
  }

  &__main {
    @include media-breakpoint-up(lg) {
      padding-right: 65px;
    }
  }

  &__downloads {
    a {
      // color: #ae1a22;
    }
  }

  &__badge {
    @include badge();

    &--onRequest {
      border-color: #61b2be;
      color: #61b2be;
    }

    &--retired {
      border-color: #e3b638;
      color: #e3b638;
    }
  }
}

.productCardList {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    margin-right: -20px;
    margin-left: -20px;
  }
}

.productCard {
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-right: 20px;
    margin-left: 20px;
    width: calc(50% - 40px);
  }

  @include media-breakpoint-up(xl) {
    width: calc(33.33% - 40px);
  }

  &__badge {
    @include badge();
    position: absolute;
    bottom: 20px;
    right: 10px;

    &--onRequest {
      border-color: #61b2be;
      color: #61b2be;
    }

    &--retired {
      border-color: #e3b638;
      color: #e3b638;
    }
  }

  &__figure {
    margin-bottom: 0;
    position: relative;
    text-align: center;
    background: #fafafa;
    padding-bottom: 10px;

    svg {
      max-width: 50%;
      height: auto;
      margin: 25%;
    }
  }

  &__main {
    padding: 20px;
    font-size: 12px;
  }

  &__title {
    font-size: 18px;
    padding: 20px;
    background-color: #1b2c65;
    color: white;
  }

  &__subtitle {
    font-size: 14px;
    text-transform: uppercase;
  }

  &__info {
    margin-bottom: 2em;
  }

  &__link {
    color: #ffffff;

    @include hover() {
      color: #ffffff;
      text-decoration: $link-hover-decoration;
    }
  }

  &__description,
  &__order {
    margin-bottom: 6px;
  }
}

.productTabs {
  border-color: $primary;
  margin-bottom: 24px;

  .nav-item {
    margin-bottom: 0;
    margin-right: 2px;
  }

  .nav-link {
    background-color: $gray-200;
    border: 0;
    border-radius: 3px 3px 0 0;
    color: $primary;
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 6px;
    transition: $transition-base;

    &:hover,
    &:focus,
    &.active {
    border-color: #162c69;
    color: #fff;
    background-color: $primary;
    }

    &.active {
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 8px;
    }
  }
}

.light-gallery-plugin {
  > a {
    position: relative;
    margin: 5px;
    display: inline-block;

    img {
      opacity: 0.6;
    }

    &:after {
      content: "\f52a";
      font-family: bootstrap-icons;
      color: #000000;
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}
