.appFooter {
  background-color: #242b31;
  color: #fff;
  margin-top: auto;

  &__top {
    background-color: #0b1737;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__middle {
    background-color: #0f235a;
    font-size: 20px;
    padding-top: 45px;
    padding-bottom: 60px;

    h2 {
      font-size: 22px;
      margin-bottom: 0;
    }
  }

  &__bottom {
    padding-top: 30px;
    padding-bottom: 15px;

    .container {
      display: flex;

      .socialIcons {
        margin-left: auto;

        .linkedin__link {
          img {
            height: 40px;
            margin-top: 2px;
          }
        }
      }
    }
  }

  a {
    color: #fff;
  }
}

.footerMenu {
  @include list-unstyled;
  margin: 0 -$grid-gutter-width / 2;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  > li {
    margin: 0 $grid-gutter-width / 2 3em;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    > a {
      font-size: 18px;
      font-weight: 700;
    }

    > ul {
      margin-top: 1.5em;
    }
  }

  ul {
    @include list-unstyled;
    margin: 0;
  }
}
