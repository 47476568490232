html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.appBody {
  flex-shrink: 0;
}

.appContainer {
  margin-top: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 65px;
    margin-bottom: 65px;
  }
}

.appColumns {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 65px;
    margin-bottom: 65px;
  }
}

.appSidebar {
  flex: 0 0 300px;
  max-width: 300px;
  margin-right: 90px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.subMenu {
  @include list-unstyled;

  ul {
    list-style: none;
    padding-left: 30px;
  }

  li {
    margin-bottom: 4px;
  }

  a {
    color: $black;
    font-size: 18px;
  }

  .selected > a {
    font-weight: 700;
  }

  .child {
    > ul {
      display: none;
    }

    &.ancestor, &.selected {
      > ul {
        display: inherit;
      }
    }
  }
}

.appSection {
  padding: 50px 0;

  @include media-breakpoint-up(md) {
    padding: 90px 0;
  }

  &__title {
    color: #162c69;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;
  }
}

.container {
  @include media-breakpoint-down(xs) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.addItem {
  img {
    margin-left: 4px !important;
    vertical-align: -3px;
  }
}

.backToTop {
  border-width: 2px;
  border-radius: 6px;
  font-size: 2em;
  line-height: 1;
  opacity: 0;
  padding: 0;
  transition: $transition-base;
  visibility: hidden;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: $zindex-tooltip;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  svg {
    transform: rotate(180deg);
    vertical-align: top;
  }
}
