.appNav {
  @include media-breakpoint-up(lg) {
    margin-left: auto;
  }
}

.appMenu {
  @include list-unstyled;
  margin: 0;

  @include media-breakpoint-down(md) {
    padding: 20px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  &__topItem {
    font-size: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.5em;
    }

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 20px;
    }
  }

  &__topLink {
    color: #353535;
    display: block;

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($color: $primary, $amount: 50%);
      outline: none;
    }

    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
      padding: 39px 20px;
      transition: all .15s;
      white-space: nowrap;
    }
  }

  &__topItem:hover &__topLink {
    @include media-breakpoint-up(lg) {
      background-color: $primary;
      color: #fff;
    }
  }

  &__subMenuContainer {
    @include media-breakpoint-up(lg) {
      background-color: $primary;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear 0.3s;

      li:hover > & {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.15s;
      }
    }
  }

  &__subMenu {
    @include list-unstyled;
    margin: 0;

    @include media-breakpoint-up(lg) {
      column-count: 3;
      column-gap: 15px;
      padding: 40px;
      max-width: 1000px;
      margin: auto;
    }

    ul {
      @include list-unstyled;
      margin: 0;
    }

    a {
      color: #353535;
      display: block;

      @include media-breakpoint-up(lg) {
        color: #fff;
      }

      &:focus {
        // box-shadow: 0 0 0 0.2rem lighten($color: $primary, $amount: 50%);
        outline: none;
        text-decoration: underline;
      }
    }

    > li {
      display: inline-block;
      font-size: 18px;
      margin-bottom: 1.5em;
      width: 100%;

      > a {
       font-weight: 700;
     }
    }
  }
}
