$headerHeight: 73px;
$headerTopHeight: 64px;
$sidebarWidth: 320px;
$cmsToolbarHeight: 46px;
$heightContainerLeftPadding: calc((100% - 1110px) / 2);
$heightContainerRightPadding: calc((100% - 1328px) / 2);

.appHeader {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: -$headerTopHeight;
  z-index: $zindex-sticky;

  @include media-breakpoint-down(md) {
    top: 0;
  }

  .cms-toolbar-expanded & {
    top: -($headerTopHeight - $cmsToolbarHeight);

    @include media-breakpoint-down(md) {
      top: $cmsToolbarHeight;
    }
  }

  &__mobile {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 20px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__sidebar {
    @include media-breakpoint-down(md) {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 4px 4px rgba(0,0,0,.25);
      overflow-y: auto;
      position: fixed;
      top: $headerHeight;
      bottom: 0;
      left: -$sidebarWidth;
      transition: left 0.15s;
      width: $sidebarWidth;

      .cms-toolbar-expanded & {
        top: $headerHeight + $cmsToolbarHeight;
      }

      &.is-active {
        left: 0;
      }
    }
  }

  &__overlay {
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .15s;

    &.is-active {
      opacity: 0.75;
      visibility: visible;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__top {
    background-color: #0b1737;
    padding: 12px 20px 16px;

    @include media-breakpoint-up(lg) {
      padding: 20px 40px 16px;
    }

    @include media-breakpoint-up(xl) {
      padding-right: $heightContainerRightPadding;
      padding-left: $heightContainerLeftPadding;
    }
  }

  &__topContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include media-breakpoint-up(xl) {
      padding-right: 20px;
    }
  }

  &__login {
    margin-bottom: 16px;
    margin-left: auto;
    min-width: 190px;

    @include media-breakpoint-up(lg) {
      margin: 0 15px;
    }
  }

  &__main {
    position: relative;
  }

  &__mainContainer {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      padding: 0 20px;
    }

    @include media-breakpoint-up(xl) {
      padding-right: $heightContainerRightPadding;
      padding-left: $heightContainerLeftPadding;
    }
  }
}

.languageChooser {
  @include list-unstyled;
  display: flex;
  margin: 0 0 10px;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }

  li:not(:last-child)::after {
    content: "-";
  }

  a {
    color: #fff;
    padding: 0 4px;
  }
}

.headerLinks {
  @include list-unstyled;
  display: flex;
  margin: 0 0 10px;

  @include media-breakpoint-up(lg) {
    margin: 0 10px;
  }

  &__item {
    @include media-breakpoint-up(lg) {
      margin: 0 10px;
    }
  }

  &__link {
    color: #fff;
    padding: 0 5px;

    &:hover {
      color: #fff;
    }
  }
}

.appLogo {
  margin-right: 20px;

  @include media-breakpoint-down(md) {
    &--lg {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    &__img {
      width: 165px;
      height: 32px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 0;
    width: 217px;

    &__img {
      width: 100%;
      height: auto;
    }
  }
}
