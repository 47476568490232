.summary-page {
  padding-top: 10px;

  .summary-page-content {
    padding-top: 20px;

    .page {
      padding-bottom: 20px;

      .page-title {
        padding-bottom: 10px;
      }
    }
  }
}
