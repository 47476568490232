.cookie-bar {
  padding: 15px 30px 15px;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  background: #616265;
  color: #fff;
  box-sizing: border-box;
  z-index: 9999;
  opacity: .9;

  .cc-cookie-btn {
    background-image: none;
    margin-bottom: 0;
    border-radius: 3px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ddd;
    background-clip: padding-box;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 15px;

    &.cc-cookie-accept {
      background-color: #0bf;
      border: 1px solid #0bf;
      color: white;
    }
  }
}
