.appContent {
  font-size: 17px;
  line-height: 1.37;

  a {
    text-decoration: underline;
  }
}

h1 {
  color: #ae1a22;
  margin-bottom: 0.3em;

  @include media-breakpoint-down(md) {
    font-size: 2.25rem;
  }
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

p,
ul,
ol,
figure {
  margin-bottom: 1.37em;
}

.blockquote, blockquote {
  border-color: #ccc;
  border-style: solid;
  border-width: 0 0 0 5px;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-style: italic;
  font-size: 17px;
  margin: 16px 40px;
  padding: 2px 8px 2px 20px;
}
