.summary-page {
  .page {
    padding: 20px 0;
    border-bottom: 1px dashed #cccccc;

    > div {
      width: 100%;
    }

    .page-title {
      a {
        font-size: 25px;
      }
    }

    img {
      max-width: 300px;
      float: right;
    }

    .page-description {
      color: #666666;
      font-style: italic;
    }
  }
}
