.productsSection {
  &__description {
    font-size: 21px;
  }
}

.productsBlock {
  margin-top: 70px;
  text-align: center;
}

.productOverview {
  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }

  &__inner {
    display: inline-block;
    padding: 0 45px;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 0 75px;
    }
  }
}

.productOverviewDetails {
  @include list-unstyled;
  margin: 0;

  &__link {
    font-size: 12px;
    position: absolute;
    text-align: left;
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      white-space: nowrap;
      overflow: hidden;
    }

    &--1 {
      top: 8.8%;
      left: 81%;
      max-width: 19%;
    }

    &--2 {
      top: 11.3%;
      right: 74%;
      max-width: 26%;
    }

    &--3 {
      top: 14.3%;
      left: 81%;
      max-width: 19%;
    }

    &--4 {
      top: 35.3%;
      left: 80%;
      max-width: 20%;
    }

    &--5 {
      top: 41.1%;
      right: 77%;
      max-width: 23%;
    }

    &--6 {
      top: 46%;
      left: 79%;
      max-width: 21%;
    }

    &--7 {
      top: 51.3%;
      left: 81%;
      max-width: 19%;
    }

    &--8 {
      top: 55.4%;
      right: 85%;
      max-width: 15%;
    }

    &--9 {
      top: 61.7%;
      left: 82%;
      max-width: 18%;
    }

    &--10 {
      top: 71.6%;
      left: 89%;
      max-width: 11%;
    }

    &--11 {
      top: 74.2%;
      right: 77%;
      max-width: 23%;
    }

    &--12 {
      top: 81%;
      left: 82%;
      max-width: 18%;
    }

    &--13 {
      top: 80.8%;
      right: 87%;
      max-width: 13%;
    }

    &--14 {
      top: 86.6%;
      right: 86%;
      max-width: 14%;
    }

    &--15 {
      top: 10%;
      left: 85%;
      max-width: 15%;
    }

    &--16 {
      top: 16%;
      right: 80%;
      max-width: 20%;
    }

    &--17 {
      top: 34%;
      right: 79%;
      max-width: 21%;
    }

    &--18 {
      top: 34%;
      left: 81%;
      max-width: 19%;
    }

    &--19 {
      top: 50%;
      right: 77%;
      max-width: 23%;
    }

    &--20 {
      top: 50%;
      left: 79%;
      max-width: 21%;
    }

    &--21 {
      bottom: 0;
      right: 81%;
      max-width: 19%;
    }

    @include media-breakpoint-down(sm) {
      &--1 {
        left: 85%;
      }

      &--2 {
        right: 77%;
      }

      &--3 {
        left: 86%;
      }

      &--4 {
        left: 85%;
      }

      &--5 {
        right: 81%;
      }

      &--6 {
        left: 84%;
      }

      &--7 {
        top: 54%;
        left: 86%;
      }

      &--8 {
        right: 89%;
      }

      &--9 {
        left: 87%;
      }

      &--11 {
        right: 80%;
      }

      &--12 {
        left: 87%;
      }

      &--13 {
        right: 88%;
      }

      &--14 {
        right: 88%;
      }

      &--21 {
        right: 88.5%;
      }
    }
  }
}

@media (min-width: 992px) {
  .tower_bottom {
    position: absolute;
    bottom: 35px;
  }
}
